/**
 * 项目   vue
 * 作者   loufei
 * 时间   2018/2/16
 */
import SockJS from 'sockjs-client';
const Stomp = require('stompjs');
let JGPWsClient;
export default class Socket {
    static connect(host, callback) {
        if (host[host.length - 1] !== '/') {
            host += '/';
        }
        JGPWsClient = Stomp.over(new SockJS(host + 'jgp-ws-server'));// 使用stomp子协议的WebSocket 客户端
        JGPWsClient.debug = () => {
            console.log('debug -----')
        }
        JGPWsClient.connect({}, (frame) => { // 链接Web Socket的服务端。
            if (callback) callback(frame);
        });
    }

    // 订阅消息
    static subscribeTopic(topicName, callback) {
        JGPWsClient.subscribe('/topic' + topicName, (respnose) => {
            if (callback) callback(respnose);
        });
    }
}
