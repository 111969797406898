<template>
    <div :id="_comId" :class="__class">
        <ul v-if="!isShowRoot">
            <jgp-tree-item
                v-if="refresh"
                v-for="(child, index) in node.children"
                _class="root"
                :key="index"
                :_url="getUrl"
                :_check="hasCheck"
                :_single="single"
                :_select_next_level="getSelectNextLevel"
                :_lazy="isLazy"
                :_extend="isExtend"
                :_enable="isEnable"
                :_dot_line="hasDotLine"
                :_ajax_param="getAjaxParam"
                :_selected_keys="getSelectedKeys"
                :_node="child"
                :_format="format"
                :_onclick="onclick"
                :_onload="doOnload"
                :_tools="tools"
                :_real_level="1"
                :_show_root="isShowRoot"
                @checked-change="doCheckedChange"
                @shift-click="childShiftClick">
            </jgp-tree-item>
        </ul>
        <ul v-else>
            <jgp-tree-item
                v-if="refresh"
                _class="root"
                :_url="getUrl"
                :_check="hasCheck"
                :_single="single"
                :_select_next_level="getSelectNextLevel"
                :_lazy="isLazy"
                :_extend="isExtend"
                :_enable="isEnable"
                :_dot_line="hasDotLine"
                :_ajax_param="getAjaxParam"
                :_selected_keys="getSelectedKeys"
                :_node="getRoot"
                :_format="format"
                :_onclick="onclick"
                :_onload="doOnload"
                :_tools="tools"
                :_real_level="0"
                :_show_root="isShowRoot"
                @checked-change="doCheckedChange"
                @shift-click="childShiftClick">
            </jgp-tree-item>
        </ul>
    </div>
</template>

<script>
import Check from 'check-types'
import Common from '../../utils/common'

/**
     *
     * 项目   jgp-front-pc
     * 作者   loufei
     * 时间   2018/9/5
     */
export default {
    data() {
        return {
            refresh: true,
            nodeList: [],
            loadCount: 0,
            node: {
                key: '-1',
                title: '根目录',
                folder: true,
                children: []
            }
        }
    },
    props: {
        _ajax_param: String | Object,
        _url: String,
        _root: {
            type: String,
            default: '根'
        },
        _format: String,
        _show_root: {
            type: String | Boolean,
            default: true
        },
        _single: {
            type: String | Boolean,
            default: false
        },
        _select_next_level: {
            type: String | Boolean,
            default: true
        },
        _enable: {
            type: String | Boolean,
            default: true
        },
        _check: {
            type: String | Boolean,
            default: true
        },
        _lazy: {
            type: String | Boolean,
            default: false
        },
        _extend: {
            type: String | Boolean,
            default: false
        },
        _dot_line: String | Boolean,
        _selected_keys: String | Array,
        _onclick: String | Function,
        _onload: String | Function,
        _onchange: String | Function,
        _tools: String
    },
    computed: {
        getAjaxParam() {
            return Check.object(this.ajax_param) ? this.ajax_param : Common.toJson(this.ajax_param);
        },
        hasCheck() {
            return Common.toBool(this.check);
        },
        isLazy() {
            return Common.toBool(this.lazy);
        },
        isExtend() {
            return Common.toBool(this.extend);
        },
        isEnable() {
            return Common.toBool(this.enable);
        },
        isShowRoot() {
            return Common.toBool(this.show_root);
        },
        hasDotLine() {
            return Common.toBool(this.dot_line);
        },
        getSelectedKeys() {
            return Common.toJson(this.selected_keys);
        },
        getRoot() {
            var node = this.node;
            node.title = this.root;
            return node;
        },
        getUrl() {
            return this.url;
        },
        getSelectNextLevel() {
            return Common.toBool(this.select_next_level)
        }
    },
    methods: {
        reload() {
            const _this = this;
            _this.refresh = false;
            _this.$nextTick(() => {
                _this.refresh = true;
                _this.load();
            })
        },
        childShiftClick() {
        },
        clearChecked() {
            let children = this.$children;
            for (let child of children) {
                child.clearChecked();
            }
        },
        plusLoadCount() {
            this.loadCount++
        },
        minusLoadCount() {
            this.loadCount--
        },
        doOnload(data) {
            if (this.loadCount === 0) {
                if (this.onload) Common.doFn(this.onload, this.node);
            }
        },
        setSelectedKeys(keys) {
            this.selected_keys = keys;
        },
        getChildSelected(node, needHalf) {
            var result = [];
            var children = node.children;
            for (let i = 0; i < children.length; i++) {
                var item = children[i];
                if (needHalf) {
                    if (item.is_checked) {
                        result.push(item);
                    }
                } else {
                    if (item.is_checked && !item.is_half) {
                        result.push(item);
                    }
                }

                if (item.children && item.children.length > 0) {
                    result = result.concat(this.getChildSelected(item, needHalf));
                }
            }
            return result;
        },
        getSelected(needHalf = false) {
            const nodes = this.getSelectedNodes(needHalf);
            let result = [];
            for (let i = 0; i < nodes.length; i++) {
                var node = nodes[i];
                result.push(node.bean);
            }
            return result;
        },
        getSelectedNodes(needHalf = false) {
            return this.getChildSelected(this.node, needHalf)
        },
        setEnable(flag) {
            this.enable = flag;
        },
        checkedAll() {
            let children = this.$children;
            for (let child of children) {
                child.checkedAll();
            }
        },
        doCheckedChange(args) {
            if (this.onchange) {
                Common.doFn(this.onchange, args);
            }
        },
        load(param) {
            if (this.isShowRoot) {
                return;
            }
            const _this = this;
            if (!_this.getUrl) return;
            if (!param) {
                param = {}
            }
            const rootCom = _this;
            if (_this.node.folder || _this.isLazy) {
                rootCom.plusLoadCount();
                _this.showLoading = true;
                const ajaxParam = Object.assign({
                    parentId: _this.node.key ? _this.node.key : -1,
                    lazy: _this.isLazy
                }, _this.getAjaxParam, param)

                Common.post(_this.getUrl, ajaxParam, (result) => {
                    _this.$set(_this.node, 'children', result);
                    _this.showLoading = false;
                    rootCom.minusLoadCount();
                    rootCom.doOnload();
                }, (result) => {
                    _this.$set(_this.node, 'children', [])
                    _this.showLoading = false;
                    rootCom.minusLoadCount();
                    rootCom.doOnload();
                }, false)
            }
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {
    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {

    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {

    },
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
        this.load();
    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {
    },
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {
    },
    /* keep-alive 组件激活时调用。 */
    activated() {
    },
    /* keep-alive 组件停用时调用。 */
    deactivated() {
    },
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {
    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {
    }
}
</script>

<style>

</style>
