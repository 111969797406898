<template>
    <div :id="_comId" class="jgp-mask">
        <jgp-dialog
            @mousedown="xxxx(index)"
            v-for="(dialog,index) in dialogs"
            :_id="dialog.id"
            :key="`dialog-${index}`"
            :_zindex="dialog.zIndex"
            :_url="dialog.url"
            :_width="dialog.width"
            :_height="dialog.height"
            :_left="dialog.left"
            :_top="dialog.top"
            :_full="dialog.full"
            :_title="dialog.title"
            :_default_tools="dialog.defaultTools"
            :origin="dialog.origin"
            :top="dialog.isTop"
            :_mask="dialog.mask"
            :_onloaded="dialog.onLoaded"
            :_index="index">
        </jgp-dialog>
        <jgp-alert v-if="alert"
                   :_zindex="alertZindex"
                   :_alert="alert">
        </jgp-alert>
        <jgp-loading v-if="loadingFlag">
        </jgp-loading>
        <jgp-album v-if="album"
                   :_current="album.current"
                   :_list="album.list">
        </jgp-album>
    </div>
</template>

<script>
import Common from '../../utils/common'
import JgpDialog from './JgpDialog.vue'
import JgpAlert from './JgpAlert.vue'
import JgpLoading from './JgpLoading.vue'
import JgpAlbum from './../layout/JgpAlbum.vue'
import Check from 'check-types'
/*
     * 项目   vue
     * 作者   loufei
     * 时间   2018/2/22
     */
export default {
    defaultConfig: {},
    components: { JgpDialog, JgpAlert, JgpLoading, JgpAlbum },
    data() {
        return {
            id_: 'jgp-mask',
            maskZindex: -1,
            dialogs: [],
            alertZindex: 0,
            alert: null,
            album: null,
            loadingFlag: false,
            albumFlag: false
        }
    },
    computed: {
        topDialog() {
            let arr = Object.assign([], this.dialogs)
            if (arr.length > 0) {
                arr.sort((a, b) => {
                    return a.zIndex > b.zIndex ? -1 : a.zIndex === b.zIndex ? 0 : 1
                })
            }
            return arr[0];
        }
    },
    methods: {
        openDialog(dialogOptions) {
            if (!this.dialogs) {
                this.dialogs = [];
            }
            let size = this.getSize(dialogOptions.full, dialogOptions.width, dialogOptions.height);
            const index = this.dialogs.length + 1;
            let position = this.getPosition(size, index);
            dialogOptions['zIndex'] = Common.zIndex() + 2;
            dialogOptions['left'] = position.left;
            dialogOptions['top'] = position.top;
            this.dialogs.push(dialogOptions);
        },
        closeDialog(args, index) {
            let target = index ? this.dialogs[index] : this.topDialog;
            let copyArgs = Object.assign({}, { args: args });
            let flag = true;
            if (target && target.callback) {
                flag = Common.doFn(target.callback, copyArgs.args, window, target.origin);
            }
            if (flag === undefined || flag === true) {
                if (index === undefined) {
                    index = this.dialogs.indexOf(this.topDialog);
                }
                this.dialogs.splice(index, 1)
            }
        },
        closeAllDialog() {
            this.dialogs = [];
        },
        openAlert(alertOptions) {
            this.alertZindex = Common.zIndex() + 2;
            this.alert = alertOptions;
        },
        openAlbum(albumOptions) {
            this.album = albumOptions;
        },
        closeAlert() {
            this.alert = null;
        },
        closeAlbum() {
            this.album = null;
        },
        loading(show) {
            this.loadingFlag = show
        },
        closeAny() {
            if (this.alert) {
                this.closeAlert();
                return true;
            } else if (this.loadingFlag) {
                this.loading(false);
                return true;
            } else if (this.dialogs.length > 0) {
                this.closeDialog();
                return true;
            }
        },
        getFullHeight() {
            return document.body.offsetHeight;
        },
        getFullWidth() {
            return document.body.offsetWidth;
        },
        getPosition(size, index) {
            let fullHeight = this.getFullHeight();
            let fullWidth = this.getFullWidth();
            return {
                top: fullHeight === size.height ? 0 : (fullHeight - size.height - index * 30) / 2,
                left: fullWidth === size.width ? 0 : (fullWidth - size.width - index * 30) / 2
            }
        },
        getSize(fullFlag, width, height) {
            return {
                width: fullFlag ? this.getFullWidth : (Check.number(width) ? width : Number(width.replace(/[^0-9]/ig, ""))),
                height: fullFlag ? this.getFullHeight : (Check.number(height) ? height : Number(height.replace(/[^0-9]/ig, "")))
            }
        },
        xxxx(index) {
            this.dialogs[index].zIndex = Common.zIndex() + 2;
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {
    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {
    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {
    },
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
        // this.$emit('mounted')
    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {
    },
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {
    },
    /* keep-alive 组件激活时调用。 */
    activated() {
    },
    /* keep-alive 组件停用时调用。 */
    deactivated() {
    },
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {
    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {
    }
}
</script>
