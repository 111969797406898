<template>
    <div :_id="_comId" :class="`${__class} ${styleClass}  col-${col} `" v-show="__show" :style="{padding:padding}">
        <jgp-text v-if="!isMulti" @onClick="onInputClick" @onChange="onInputChange" @onFocus="onFocus" @onBlur="onBlur" :_value="getValue"
                  :_label="label"
                  :_label_width="label_width"
                  :_name="name"
                  _col="full"
                  :_required="required"
                  :_placeholder="placeholder"
                  :_tip="tip"
                  :_hidden="hidden"
                  :_readonly="getReadonly||notInput"
                  :_disabled="getDisabled"
                  :_submit="getSubmit"
                  _padding="0"
                  ref="text"
                  :_hastool="hastool">

            <v-popover slot="tool" v-show="canDrop"
                       popoverClass="form-drop select-drop"
                       :auto-hide="false"
                       placement="bottom-end"
                       :open="showPanel">
                <a class="tool-label" href="javascript:void(0);" @click.stop="showPanel=true">
                    <i :class="`fa fa-caret-${showPanel?'up':'down'}`">&nbsp;</i>
                </a>
                <template slot="popover">
                    <div class="drop-content" ref="drop_scroller" :style="{'max-width':max_width,'max-height':max_height,'overflow-y': 'auto'}">
                        <div :style="{'width':'100%','height':'100%'}" @mouseenter="mouseInDropArea" @mouseleave="mouseOutDropArea">
                            <div v-if="mode=='list'" :key="index" v-for="(item,index) in getRealList" :class="item.value==getValue?'select':'normal'" @click="select(item)">
                                <span>{{item.label}}</span>
                            </div>
                            <jgp-tree2 v-if="mode=='tree'"
                                       ref="tree"
                                       :_check="tree_check"
                                       :_lazy="tree_lazy"
                                       :_root="tree_root"
                                       :_show_root="tree_show_root"
                                       :_single="true"
                                       :_dot_line="tree_dot_line"
                                       :_ajax_param="tree_ajax_param"
                                       :_selected_keys="tree_selected_keys"
                                       :_onclick="selectTreeNode"
                                       :_onload="doOnTreeLoad"
                                       :_url="url"></jgp-tree2>
                        </div>
                    </div>
                </template>
            </v-popover>

        </jgp-text>

        <jgp-tags v-else
                  @onClick="onInputClick" @onTagFocus="onTagFocus" @onTagBlur="onTagBlur" v-model="ckbValue"
                  @onTagChange="onTagChange"
                  :_list="getRealList"
                  :_label="label"
                  :_label_width="label_width"
                  :_name="name"
                  :_row="tags_row"
                  _col="full"
                  :_required="required"
                  :_placeholder="placeholder"
                  :_tip="tip"
                  :_hidden="hidden"
                  :_readonly="readonly"
                  :_disabled="disabled"
                  _padding="0px"
                  :_display_option="display_option"
                  :_submit="getSubmit"
                  ref="text"
                  :_hastool="hastool">

            <v-popover slot="tool" v-show="canDrop"
                       popoverClass="form-drop select-drop"
                       :auto-hide="false"
                       placement="bottom-end"
                       :open="showPanel">
                <a class="tool-label" href="javascript:void(0);" @click.stop="showPanel=true">
                    <i :class="`fa fa-caret-${showPanel?'up':'down'}`">&nbsp;</i>
                </a>
                <template slot="popover">
                   <!--  <input class="search" type="text" placeholder="搜索"> -->
                    <div class="drop-content" ref="tags_scroller" :style="{'max-width':max_width,'max-height':max_height,'overflow-y': 'auto'}">
                        <div v-if="mode=='list'" :style="{'width':'100%','height':'100%'}" @mouseenter="mouseInDropArea" @mouseleave="mouseOutDropArea">
                            <div class="checkbox-item" :key="index" v-for="(item,index) in getRealList" :class="ckbValue.includes(item.value)?'select':'normal'">
                                <label :for="`${_comId}_${index}`" class="radio" @click="doClick(item)">
                                    <span class="radio-bg"></span>
                                    <input type="checkbox" :name="`ckb_${_comId}`"
                                           v-model="ckbValue"
                                           :value="item.value"
                                           :id="`${_comId}_${index}`"/>
                                    <span class="radio-on"></span>
                                    <span>{{item.label}}</span>
                                </label>
                            </div>
                        </div>

                        <jgp-tree2 v-if="mode=='tree'"
                                   ref="tree"
                                   :_check="tree_check"
                                   :_lazy="tree_lazy"
                                   :_extend="tree_extend"
                                   :_root="tree_root"
                                   :_show_root="tree_show_root"
                                   :_single="true"
                                   :_dot_line="tree_dot_line"
                                   :_ajax_param="tree_ajax_param"
                                   :_selected_keys="tree_selected_keys"
                                   :_onclick="selectTreeNode"
                                   :_onload="doOnTreeLoad"
                                   :_url="url"></jgp-tree2>
                    </div>
                    <div style="text-align: right;">
                        <a class="close button button-small" href="javascript:void(0);" @click.stop="selectAll()"><span>全选</span></a>
                        <a class="close button button-small" href="javascript:void(0);" @click.stop="showPanel=false"><span>关闭</span></a>
                    </div>
                </template>
            </v-popover>
        </jgp-tags>
    </div>
</template>

<script>
/**
     * 下拉组件
     * @example <jgp-drop _label="下拉" _name="drop"
     _value="china"
     _list="[{'value':'china','label':'中国'},{'value':'usa',
            'label':'美国'},{'value':'un','label':'英国'}]">
     </jgp-drop>
     * @module jgp-drop
     * @desc 用于类似于html select，加载数据源三种方式：1、异步 _url与_ajax_param(可选)，2、页面里配置 _list 3、 _active_key
     */
import Common from '../../utils/common'
import Check from 'check-types'
import Scrollbar from 'smooth-scrollbar'

/*
     * 项目   vue
     * 作者   loufei
     * 时间   2018/2/22
     */
export default {
    data() {
        return {
            styleClass: 'inline-control',
            showPanel: false,
            hastool: 'true',
            filterList: [], // 自动填充时或异步查询时过滤后的可选列表，或异步查询列表
            activeUrl: '/sys/config-drop',
            ckbValue: [],
            checkMouseIfInDropArea: false
        }
    },
    /**
         * @prop {String} _url 异步请求下拉数据列表
         * @prop {String} _ajax_param 异步请求参数
         * @prop {String} _list 下拉列表 [{'value':'cccc','label':'2222'}]
         * @prop {String} _active_key 绑定下拉源，后台维护的功能组标识
         * @prop {String} _only_show 只用来显示,不选择
         * @prop {String} _multi 是否多选 {true|false}
         * @prop {String} _value 默认值 多选默认值逗号分隔
         * @prop {String} _name
         * @prop {String} _label 标题
         * @prop {String} _label_width 标题宽度
         * @prop {String} _placeholder 提示信息
         * @prop {String} _tip 长文本提示 在LABEL hover的时候显示
         * @prop {String} _col 所占列宽
         * @prop {String} _required 是否可用 {默认:false}
         * @prop {String} _readonly 是否只读 {默认:false}
         * @prop {String} _disabled 是否禁用 {默认:false}
         * @prop {String} _not_input 是否可以手动输入 {默认:false} 默认是可以，设置为true 则只能选择，不能输入
         * @prop {String} _padding 内边距 {默认(只负责 上、左、右):'5px 5px 0 5px'}
         * @prop {String} _max_width 最大宽度 {默认:_max_width='300px'} 超出后会出现横向滚动条
         * @prop {String} _max_height 最大宽度 {默认:_max_height='300px'} 超出后会出现纵向滚动条
         * @prop {String} _display_option 显示配置 _display_option='label'|'value'|'label,value'{默认:_display_option='label'}
         * @prop {String} _onchange change事件
         * @prop {String} _onclick click事件
         */
    props: {
        '_url': String,
        '_model': String,
        '_server': {
            type: String
        },
        '_ajax_param': {
            type: Object,
            default() {
                return {}
            }
        },
        '_value': String | Boolean | Number | Array,
        '_list': {
            type: String | Object,
            default: '[]'
        },
        '_active_key': String,
        '_placeholder': String,
        '_tip': String,
        '_label': String,
        '_only_show': {
            type: String,
            default: 'false'
        },
        '_multi': {
            type: String | Boolean,
            default: 'false'
        },
        '_mode': {
            type: String,
            default: 'list'// list tree
        },
        '_filter': {
            type: String,
            default: 'true'
        },
        '_name': String,
        '_col': {
            type: String | Number,
            default: '6'
        },
        '_tags_row': {
            type: String,
            default: '1'
        },
        '_required': String | Boolean,
        '_readonly': String,
        '_disabled': String,
        '_not_input': String,
        '_label_width': {
            type: String,
            default: '80px'
        },
        '_padding': {
            type: String,
            default: '5px 5px 0'
        },
        '_max_width': {
            type: String,
            default: '300px'
        },
        '_max_height': {
            type: String,
            default: '300px'
        },
        '_display_option': {
            type: String,
            default: 'label'
        },
        '_onchange': String,
        '_onblur': String,
        '_onclick': String,
        '_submit': {
            type: String | Boolean,
            default: 'true'
        },
        '_tree_root': {
            type: String,
            default: '根'
        },
        '_tree_show_root': {
            type: String | Boolean,
            default: 'false'
        },
        '_tree_check': {
            type: String | Boolean,
            default: 'false'
        },
        '_tree_lazy': {
            type: String | Boolean,
            default: 'false'
        },
        '_tree_extend': {
            type: String | Boolean,
            default: 'false'
        },
        '_tree_dot_line': {
            type: String | Boolean,
            default: 'false'
        },
        '_tree_selected_keys': {
            type: String | Array,
            default: () => {
                return []
            }
        },
        '_tree_onclick': String,
        '_tree_ajax_param': String | Object
    },
    computed: {
        // 真实提交的值被设置到隐藏域，显示的值非提交值 需要配置hidden
        hidden() {
            return {
                status: true,
                disValue: this.getDisplayValue
            }
        },
        getList() {
            if (Check.string(this.list) && Common.trim(this.list)) {
                return Common.toJson(this.list);
            } else {
                return this.list;
            }
        },
        getRealList() {
            if (Common.toBool(this.filter)) {
                if (this.filterList.length > 0) {
                    return this.filterList;
                } else {
                    return this.getList;
                }
            } else {
                return this.getList;
            }
        },
        canDrop() {
            return !(this.getReadonly || this.getDisabled || Common.toBool(this.only_show));
        },
        getDisplayValue() {
            return this.getLabel(this.checkItem(this.getValue))
        },
        getValueList() {
            return this.getList.map(item => item.value);
        },
        getValue() {
            let vals = [];
            let value;
            if (Check.string(this.value) && Common.trim(this.value) !== '') {
                if (this.value.includes(',')) {
                    value = this.value.split(',');
                } else {
                    value = Common.toJson(this.value);
                }
            } else if (Check.number(this.value)) {
                value = this.value + '';
            }
            if (this.isMulti) {
                if (value) {
                    if (Check.array(value)) {
                        value.forEach((val) => {
                            if (this.getValueList.includes(val)) {
                                vals.push(val);
                            }
                        })
                    } else {
                        if (this.getValueList.includes(value)) {
                            vals.push(value);
                        }
                    }
                }
                this.ckbValue = vals;
                return vals;
            } else if (Check.array(value) && this.getValueList.includes(value[0])) {
                return value[0];
            } else if (this.getValueList.includes(value)) {
                return value;
            } else if (this.getValueList.includes(this.value)) {
                return this.value;
            }
        },
        isMulti() {
            return Common.toBool(this.multi);
        },
        getReadonly() {
            return (this.form && this.form.getReadonly) || Common.toBool(this.readonly)
        },
        getDisabled() {
            return (this.form && this.form.getDisabled) || Common.toBool(this.disabled)
        },
        notInput() {
            return Common.toBool(this.not_input);
        },
        form() {
            return this.getForm(this);
        },
        formGroup() {
            return this.getFormGroup(this);
        },
        getSubmit() {
            return Common.toBool(this.submit);
        },
        getActiveUrl() {
            return this.server + this.activeUrl;
        }
    },
    watch: {
        showPanel(flag) {
            if (flag) this.filterList = []
        },
        value(value) {
            let selectItems = [];
            if (this.model) {
                this.load();
            }
            for (let item of this.getList) {
                if (item.value === value) {
                    selectItems.push(item);
                }
            }
            if (!!this.form && this.form.changeErrors) this.form.changeErrors(this.name, this.errors);
            if (!!this.form && this.form.setKeyValue) this.form.setKeyValue(this.name, value);
            if (this.onchange) Common.doFn(this.onchange, { value: value, item: selectItems[0] });
        }, /*,             value(value){
             let selectItems = [];
             for(let item of this.getList){
             if(item.value == value){
             selectItems.push(item);
             }
             }

             if (this.onchange) Common.doFn(this.onchange, {value:value,item:selectItems[0]});
             } */
        active_key(key) {
            this.load(key);
        },
        url() {
            this.load();
        }
    },
    methods: {
        onTagChange(args) {
            if (Check.array(args.value)) {
                this.ckbValue = args.value
            } else if (Check.string(args.value)) {
                this.ckbValue = args.value.split(',');
            }
        },
        getForm(com) {
            if (this.parentLevel > 10) {
                return undefined
            }
            let parent = com.$parent;
            if (parent.cType === 'jgp-form' || parent.cType === 'jgp-query') {
                return parent;
            } else {
                this.parentLevel += 1;
                return this.getForm(parent)
            }
        },
        getFormGroup(com) {
            let parent = com.$parent;
            if (this.parentLevel > 10 || parent.cType === 'jgp-form' || parent.cType === 'jgp-query') {
                return undefined
            }
            if (parent.cType === 'jgp-form-group') {
                return parent;
            } else {
                this.parentLevel += 1;
                return this.getFormGroup(parent)
            }
        },
        onFocus(param) {
            this.filterList = [];
            if (this.canDrop) {
                this.showPanel = true;
            }
        },
        onBlur(param) {
            if (this.filterList.length > 0) {
                if (param.value === undefined) {
                    this.value = this.filterList[0].value;
                } else if (this.filterList[0].label.indexOf(param.disValue) !== -1) {
                    this.value = undefined;
                } else {
                    this.value = param.value;
                }
            }
            if (this.onblur) {
                if (this.onblur) Common.doFn(this.onblur, { value: this.value });
            }
        },
        onInputChange(param) {
            if (param.disValue && param.disValue !== '' && Common.toBool(this.filter)) {
                let disValue = Common.trim(param.disValue);
                if (this.filterList.length > 0) this.showPanel = true;
                let filterList = []
                for (let item of this.getList) {
                    if (item.label && item.label.indexOf(disValue) !== -1) {
                        filterList.push(item);
                    }
                }
                this.$set(this, 'filterList', filterList)
            } else if (param.disValue === '') {
                this.$set(this, 'filterList', [])
                this.value = undefined;
            } else if (param.disValue === undefined) {
                this.value = param.value;
            } else {
                this.$set(this, 'filterList', [])
            }
        },
        onInputClick(param) {
            // this.showPanel = !this.showPanel
        },
        onTagFocus(param) {
            this.showPanel = true;
        },
        onTagBlur() {
            if (this.onblur) {
                if (this.onblur) Common.doFn(this.onblur, { value: this.value });
            }
        },
        checkItem(value) {
            for (let item of this.getList) {
                if (item.value + '' === value + '') {
                    return item;
                }
            }
            return undefined;
        },
        checkValue(value) {
            return this.getValue && this.getValue.includes(value);
        },
        getLabel(item) {
            if (item) {
                if (this.display_option.indexOf(',') !== -1) {
                    const displayOptions = this.display_option.split(',');
                    return item[displayOptions[0]] + '【' + item[displayOptions[1]] + '】'
                } else {
                    return item[this.display_option];
                }
            }
        },
        select(item) {
            this.value = item.value;
            this.showPanel = false;
            this.doClick(item);
        },
        doClick(item) {
            if (this.onclick) Common.doFn(this.onclick, item);
        },
        load(key) {
            if (key) {
                Common.post(this.getActiveUrl, { activeKey: key }, (result) => {
                    if (result.flag) {
                        this.$set(this, 'list', result.data.ldata);
                    }
                });
            } else if (this.url && !this.active_key) {
                Common.post(this.url, Common.toJson(this.ajax_param), (result) => {
                    if (result.flag) {
                        this.$set(this, 'list', result.data.ldata);
                    }
                });
            } else if (this.url && this.active_key) {
                Common.post(this.url, { activeKey: this.active_key }, (result) => {
                    if (result.flag) {
                        this.$set(this, 'list', result.data.ldata);
                    }
                });
            } else if (!this.url && !this.active_key && this.model) {
                Common.post(this.getActiveUrl, { model: this.model, modelKey: this.value }, (result) => {
                    if (result.flag) {
                        this.$set(this, 'list', result.data.ldata);
                    }
                });
            } else if (this.active_key && typeof this.form === 'undefined') {
                Common.post(this.getActiveUrl, { activeKey: this.active_key }, (result) => {
                    if (result.flag) {
                        this.$set(this, 'list', result.data.ldata);
                    }
                });
            }
        },
        initScroll() {
            if (!this.canDrop) {
                if (this.$refs.drop_scroller) Scrollbar.init(this.$refs.drop_scroller, { alwaysShowTracks: true, continuousScrolling: false });
                if (this.$refs.tags_scroller) Scrollbar.init(this.$refs.tags_scroller, { alwaysShowTracks: true, continuousScrolling: false });
            }
        },
        /**
             * @name val
             * @function
             * @param value {String}
             * @desc  获取值 JGP.drop('id').val()。设置值 JGP.drop('id').val('1')
             */
        val(value, submit) {
            if (Common.checkInputValue(value)) {
                this.$set(this, 'value', value);
            } else {
                if (this.isMulti) {
                    return this.ckbValue.sort().join(',');
                } else {
                    return this.getValue;
                }
            }
            if (typeof submit !== 'undefined') {
                this.$set(this, 'submit', submit);
            }
        },
        initValue() {
            if (this.isMulti) {
                this.$nextTick(() => {
                    this.ckbValue = Object.assign(this.ckbValue, this.getValue)
                })
            }
        },
        /**
             * @name reset
             * @function
             * @desc 清空已输入的内容
             */
        reset() {
            this.value = undefined;
            this.ckbValue = [];
        },
        selectTreeNode(node) {
            this.value = node.key;
            this.showPanel = false;
        },
        valid() {
            return this.required && (!!this.value || (!!this.ckbValue && this.ckbValue.length > 0));
        },
        getTreeNodeList(rootNode) {
            let result = []
            let children = rootNode.children;
            if (children) {
                for (var i = 0; i < children.length; i++) {
                    var child = children[i];
                    result.push({
                        label: child.title,
                        value: child.key,
                        bean: child.bean
                    })
                    const cc = this.getTreeNodeList(children[i]);
                    if (cc) result = result.concat(cc);
                }
            } else {
                result = [];
            }
            return result;
        },
        doOnTreeLoad(nodes) {
            const list = this.getTreeNodeList(nodes);
            this.list = list;
        },
        mouseInDropArea() {
            this.checkMouseIfInDropArea = true;
        },
        mouseOutDropArea() {
            this.checkMouseIfInDropArea = false;
        },
        hidePanel(event) {
            const _this = this;
            if (event.target.id !== 'input-' + _this.$refs.text.$el.id && !_this.isMulti && !_this.checkMouseIfInDropArea) {
                _this.$nextTick(() => {
                    _this.showPanel = false;
                })
            }
        },
        selectAll() {
            let all = this.getRealList;
            this.ckbValue = all.map(item => {
                return item.value
            })
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {
    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {
        if (this.form) {
            this.form.els.push({ name: this.name, el: this });
        }
        this.load();
    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {
        Common.addEvent(document, 'click', this.hidePanel);
    },
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
        console.log(this.canDrop)
        this.initValue();
    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {
    },
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {
        this.initScroll();
    },
    /* keep-alive 组件激活时调用。 */
    activated() {
    },
    /* keep-alive 组件停用时调用。 */
    deactivated() {
    },
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {
    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {
        Common.removeEvent(document, 'click', this.hidePanel);
    }
}
</script>
