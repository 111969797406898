<template>
    <jgp-drag-resize
        :id="_comId"
        :class="`${__class} ${fullFlag?'zoomIn max':''}`"
        v-show="__show"
        :w="getW"
        :h="getH"
        :x="left"
        :y="top"
        :style="{'z-index':zindex||1}"
        drag-handle=".top"
        @resizing="onResizing"
        @resizestop="onResizeStop"
        @dragging="onDragging"
        @dragstop="onDragStop"
    >
        <div v-if="mask" class="mask"></div>
        <div v-show="moveMask" class="move-mask"></div>
        <jgp-panel
            @mousedown="$emit('mousedown',this)"
            @animate-over="doAnimateOver"
            :_default_tools="getDefaultTools"
            ref="panel"
            :_tools="getTools"
            _width="100%"
            _height="100%"
            :_title="title"
            :_url="url"
            :origin="origin"
            :_onload="doOnContentLoaded"
        ></jgp-panel>
    </jgp-drag-resize>
</template>

<script>
import Check from "check-types";
import Common from "../../utils/common";
/*
 * 项目   vue
 * 作者   loufei
 * 时间   2018/2/22
 */
export default {
    data() {
        return {
            moveMask: false,
            fullFlag: this.full,
            xleft: undefined,
            xtop: undefined,
            tmpLeft: null,
            tmpTop: null,
            hhh: null,
            timer: null,
            collapse: null,
            tmpHeight: null
        };
    },
    props: {
        _url: String,
        _zindex: Number,
        _width: {
            type: String,
            default: "800px"
        },
        _height: {
            type: String,
            default: "600px"
        },
        _left: {
            type: String | Number
        },
        _top: {
            type: String | Number
        },
        _isTop: {
            type: Boolean
        },
        _title: {
            type: String,
            default: "标题"
        },
        _full: {
            type: Boolean,
            default: false
        },
        _default_tools: {
            type: String | Array
        },
        _mask: {
            type: String | Boolean,
            default: true
        },
        _index: Number,
        _onloaded: [String, Function],
        _callback: [String, Object]
    },
    computed: {
        getTools() {
            return [
                {
                    text: "隐藏",
                    icon: "window-minimize",
                    fn: this.hidePanel
                },
                {
                    text: "最大化最小化",
                    icon: "expand",
                    fn: this.toggleMax
                },
                {
                    text: "关闭窗口",
                    icon: "dot-circle-o",
                    fn: () => {
                        if (this.top) {
                            Common.closeDialog(undefined, this.index);
                        } else {
                            Common.closeLocalDialog(undefined, this.index);
                        }
                    }
                }
            ];
        },
        getDefaultTools() {
            if (!this.default_tools) return null;
            if (Check.array(this.default_tools)) {
                return this.default_tools;
            } else {
                return Common.toJson(this.default_tools);
            }
        },
        getFullHeight() {
            return document.body.offsetHeight;
        },
        getFullWidth() {
            return document.body.offsetWidth;
        },
        getH() {
            if (this.tmpHeight) return this.tmpHeight;
            return this.fullFlag
                ? this.getFullHeight
                : Check.number(this.height)
                    ? this.height
                    : Number(this.height.replace(/[^0-9]/gi, ""));
        },
        getW() {
            return this.fullFlag
                ? this.getFullWidth
                : Check.number(this.width)
                    ? this.width
                    : Number(this.width.replace(/[^0-9]/gi, ""));
        },
        getLeft() {
            return this.left;
        },
        getTop() {
            return this.top;
        }
    },
    methods: {
        onDragging() {
            if (!this.moveMask) this.moveMask = true;
        },
        onDragStop(left, top) {
            this.xleft = left;
            this.xtop = top;
            if (this.moveMask) {
                this.$nextTick(() => {
                    this.moveMask = false;
                });
            }
        },
        onResizeStop() {
            if (this.moveMask) {
                this.$nextTick(() => {
                    this.moveMask = false;
                });
            }
        },
        onResizing() {
            if (!this.moveMask) this.moveMask = true;
        },
        doOnContentLoaded() {
            if (this.moveMask) {
                this.$nextTick(() => {
                    this.moveMask = false;
                    if (this.onloaded) {
                        Common.doFn(this.onloaded, this);
                    }
                });
            }
        },
        doAnimateOver(args) {
            if (this.moveMask) {
                this.$nextTick(() => {
                    this.moveMask = false;
                });
            }
        },
        toggleMax(e) {
            if (this.timer) {
                window.clearTimeout(this.timer);
            }
            this.fullFlag = !this.fullFlag;
            if (this.fullFlag) {
                if (this.collapse === false) {
                    this.hidePanel();
                }

                this.tmpLeft = this.xleft;
                this.tmpTop = this.xtop;

                this.$set(this, "left", 0);
                this.$set(this, "top", 0);
            } else {
                if (this.collapse === false) {
                    this.hidePanel();
                }
                this.left = this.tmpLeft;
                this.top = this.tmpTop;
            }
            this.timer = window.setTimeout(() => {
                if (this.moveMask) this.moveMask = false;
            }, 50);
        },
        hidePanel() {
            this.$refs.panel.hidePanel(collapse => {
                this.collapse = collapse;
                if (!collapse) {
                    this.tmpHeight = 50;
                    this.$nextTick(() => {
                        this.$set(this, "left", 0);
                        this.$set(this, "top", 0);
                    });
                } else {
                    if (!this.fullFlag) {
                        this.left = this.tmpLeft;
                        this.top = this.tmpTop;
                    }
                    this.tmpHeight = null;
                    this.height = this.hhh;
                }
            });
        },
        upZindex() {
            this.zindex = Common.zIndex() + 2;
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {},
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {},
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {},
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
        this.hhh = this.getH;
        this.tmpLeft = this.left;
        this.tmpTop = this.top;
    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {},
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {},
    /* keep-alive 组件激活时调用。 */
    activated() {},
    /* keep-alive 组件停用时调用。 */
    deactivated() {},
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {},
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {}
};
</script>

<style>
</style>
