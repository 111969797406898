<template>
    <div :class="`${__class} col-${col}`" v-show="__show" :style="{width:width,height:height}">
        <div class="group-top" v-if="!!title||!!title_html">
            <div class="group-title">
                <span v-if="title">{{title}}</span>
                <span v-if="!!title_html" ref="titleHtml"></span>
            </div>
        </div>
        <div class="group-bottom clearfix">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
/**
     * 表单组
     * @module jgp-form-group
     * @desc 用于表单项分组
     */
export default {
    /**
       * Props
       * @prop {String} _title 组标题
       * @prop {String} _col 所占列数
       * @prop {String} _label_width 标题宽度 {单位:px,默认12}
       */
    props: {
        '_title': String,
        '_col': {
            type: String,
            default: '12'
        },
        _title_html: String,
        '_label_width': String
    },
    computed: {
        form() {
            return this.getForm(this);
        }
    },
    methods: {
        formLayout() {
            if (this.form) {
                return this.form.layout
            }
        },
        getForm(com) {
            if (this.parentLevel > 10) {
                return undefined
            }
            let parent = com.$parent;
            if (parent.cType === 'jgp-form' || parent.cType === 'jgp-query') {
                return parent;
            } else {
                this.parentLevel += 1;
                return this.getForm(parent)
            }
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {
    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {

    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {

    },
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
        const _this = this;
        if (_this.$refs.titleHtml) {
            let vm = new Vue({
                parent: _this,
                template: _this.title_html
            });
            vm.$mount(_this.$refs.titleHtml);
        }
    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {
    },
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {
    },
    /* keep-alive 组件激活时调用。 */
    activated() {
    },
    /* keep-alive 组件停用时调用。 */
    deactivated() {
    },
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {
    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {
    }
}
</script>
