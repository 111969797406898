/**
 * 项目   vue
 * 作者   loufei
 * 时间   2018/2/16
 */
import Vue from 'vue'
import Check from "check-types";
import Common from "./common";
import JgpLayoutBorder from '../components/layout/JgpLayoutBorder.vue'
import JgpPanel from '../components/layout/JgpPanel.vue'
import JgpTabPanel from '../components/layout/JgpTabPanel.vue'
import JgpTab from '../components/layout/JgpTab.vue'
import JgpSlider from '../components/layout/JgpSlider.vue'
import JgpDiv from '../components/layout/JgpDiv.vue'
import JgpTools from '../components/tool/JgpTools.vue'
import JgpForm from '../components/form/JgpForm.vue'
import JgpQuery from '../components/form/JgpQuery.vue'
import JgpFormGroup from '../components/form/JgpFromGroup.vue'
import JgpText from '../components/input/JgpText.vue'
import JgpHidden from '../components/input/JgpHidden.vue'
import JgpRadio from '../components/input/JgpRadio.vue'
import JgpCheckbox from '../components/input/JgpCheckbox.vue'
import JgpNum from '../components/input/JgpNum.vue'
import JgpDrop from '../components/input/JgpDrop.vue'
import JgpTags from '../components/input/JgpTags.vue'
import JgpDateTime from '../components/input/JgpDateTime.vue'
import JgpArea from '../components/input/JgpArea.vue'
import JgpMask from '../components/mask/JgpMask.vue'
import JgpBtn from '../components/button/JgpBtn.vue'
import JgpTree from '../components/tree/JgpTree.vue'
import JgpTree2 from '../components/tree/JgpTree2.vue'
import JgpTreeItem from '../components/tree/JgpTreeItem.vue'
import JgpMenu from '../components/menu/JgpMenu.vue'
import JgpMenuItem from '../components/menu/JgpMenuItem.vue'
import JgpGrid from '../components/grid/JgpGrid.vue'
import JgpGridCol from '../components/grid/JgpGridCol.vue'
import JgpList from '../components/grid/JgpList.vue'
import JgpPager from '../components/pager/JgpPager.vue'
import JgpImg from '../components/file/JgpImg.vue'
import JgpUploader from '../components/file/JgpUploader.vue'
import JgpAlbum from '../components/layout/JgpAlbum.vue'
// import JgpRichEditor from '../components/input/JgpRichEditor.vue'
import JgpAdvSite from '../components/adv/JgpAdvSite.vue'
import JgpAdvUserInfo from '../components/adv/JgpUserInfo.vue'
import JgpProcOperator from '../components/process/JgpProcOperator.vue'
import JgpTaskFlow from '../components/process/JgpTaskFlow.vue'
import JgpDialog from '../components/mask/JgpDialog.vue'
import JgpGlPile from '../components/gl/JgpGlPile.vue'

import { Base64 } from 'js-base64';
export const OPT = {
    LAYOUT_BORDER: {
        type: 'jgp-layout-border',
        component: JgpLayoutBorder
    },
    PANEL: {
        type: 'jgp-panel',
        component: JgpPanel
    },
    TAB_PANEL: {
        type: 'jgp-tab-panel',
        component: JgpTabPanel
    },
    TAB: {
        type: 'jgp-tab',
        component: JgpTab
    },
    TOOLS: {
        type: 'jgp-tools',
        component: JgpTools
    },
    FORM: {
        type: 'jgp-form',
        component: JgpForm
    },
    QUERY: {
        type: 'jgp-query',
        component: JgpQuery
    },
    FORM_GROUP: {
        type: 'jgp-form-group',
        component: JgpFormGroup
    },
    TEXT: {
        type: 'jgp-text',
        component: JgpText
    },
    HIDDEN: {
        type: 'jgp-hidden',
        component: JgpHidden
    },
    RADIO: {
        type: 'jgp-radio',
        component: JgpRadio
    },
    CHECKBOX: {
        type: 'jgp-checkbox',
        component: JgpCheckbox
    },
    NUM: {
        type: 'jgp-num',
        component: JgpNum
    },
    DROP: {
        type: 'jgp-drop',
        component: JgpDrop
    },
    TAGS: {
        type: 'jgp-tags',
        component: JgpTags
    },
    DATETIME: {
        type: 'jgp-date-time',
        component: JgpDateTime
    },
    AREAR: {
        type: 'jgp-area',
        component: JgpArea
    },
    MASK: {
        type: 'jgp-mask',
        component: JgpMask
    },
    BTN: {
        type: 'jgp-btn',
        component: JgpBtn
    },
    DIV: {
        type: 'jgp-div',
        component: JgpDiv
    },
    TREE: {
        type: 'jgp-tree',
        component: JgpTree
    },
    TREE2: {
        type: 'jgp-tree2',
        component: JgpTree2
    },
    TREE_ITEM: {
        type: 'jgp-tree-item',
        component: JgpTreeItem
    },
    MENU: {
        type: 'jgp-menu',
        component: JgpMenu
    },
    MENU_ITEM: {
        type: 'jgp-menu-item',
        component: JgpMenuItem
    },
    GRID: {
        type: 'jgp-grid',
        component: JgpGrid
    },
    GRID_COL: {
        type: 'jgp-grid-col',
        component: JgpGridCol
    },
    LIST: {
        type: 'jgp-list',
        component: JgpList
    },
    PAGER: {
        type: 'jgp-pager',
        component: JgpPager
    },
    SLIDER: {
        type: 'jgp-slider',
        component: JgpSlider
    },
    IMG: {
        type: 'jgp-img',
        component: JgpImg
    },
    UPLOAD: {
        type: 'jgp-uploader',
        component: JgpUploader
    },
    ALBUM: {
        type: 'jgp-album',
        component: JgpAlbum
    }, /* ,    RICH_EDITOR: {
        type: 'jgp-rich-editor',
        component: JgpRichEditor
    } */
    ADV_SITE: {
        type: 'jgp-adv-site',
        component: JgpAdvSite
    },
    ADV_USER_INFO: {
        type: 'jgp-adv-user-info',
        component: JgpAdvUserInfo
    },
    PROC_OPERATOR: {
        type: 'jgp-proc-operator',
        component: JgpProcOperator
    },
    TASK_FLOW: {
        type: 'jgp-task-flow',
        component: JgpTaskFlow
    },
    DIALOG: {
        type: 'jgp-dialog',
        component: JgpDialog
    },
    GL_PILE: {
        type: 'jgp-gl-pile',
        component: JgpGlPile
    }

}

export const ASYNC_OPT = {
    OPT_LAYOUT_BORDER: {
        type: 'jgp-layout-border',
        component: '../components/layout/JgpLayoutBorder.vue'
    },
    OPT_PANEL: {
        type: 'jgp-panel',
        component: '../components/panel/JgpPanel.vue'
    },
    OPT_TOOLS: {
        type: 'jgp-tools',
        component: '../components/tool/JgpTools.vue'
    },
    OPT_FORM: {
        type: 'jgp-form',
        component: '../components/form/JgpForm.vue'
    },
    OPT_FORM_GROUP: {
        type: 'jgp-form-group',
        component: '../components/form/JgpFromGroup.vue'
    },
    OPT_TEXT: {
        type: 'jgp-text',
        component: '../components/input/JgpText.vue'
    },
    OPT_DATETIME: {
        type: 'jgp-date-time',
        component: '../components/input/JgpDateTime.vue'
    }
}

export default class Options {
    static globalComponent() {
        for (let key in OPT) {
            let value = OPT[key];
            Vue.component(value.type, value.component);
        }
    }

    static USER_CONFIG = {}

    static CONFIG = {
        http: {
            autoMask: true
        },
        SERVER_PROP: window.PROP ? Base64.decode(Base64.decode(window.PROP)) : undefined
    }

    static getConfig() {
        return Object.assign(Options.CONFIG, Options.USER_CONFIG);
    }

    static option(type, key, opt) {
        if (!window['JGP_OPTIONS']) {
            window['JGP_OPTIONS'] = {}
        }
        if (!window['JGP_OPTIONS'][type]) {
            window['JGP_OPTIONS'][type] = {}
        }

        if (Common.isObject(opt)) {
            window['JGP_OPTIONS'][type][key] = opt;
        } else {
            if (type && !key) {
                return window['JGP_OPTIONS'][type]
            } else if (type && key) {
                return window['JGP_OPTIONS'][type][key];
            }
        }
    }

    static com(type, comId, com) {
        if (!window['JGP_COMS']) {
            window['JGP_COMS'] = {}
        }
        if (!window['JGP_COMS'][type]) {
            window['JGP_COMS'][type] = {}
        }

        if (Common.isObject(com)) {
            window['JGP_COMS'][type][comId] = com;
        } else {
            if (type && !comId) {
                return window['JGP_COMS'][type]
            } else if (type && comId) {
                return window['JGP_COMS'][type][comId];
            }
        }
    }

    static getDialog(comId) {
        var type = 'jgp-dialog';
        if (!top['JGP_COMS']) {
            top['JGP_COMS'] = {}
        }
        if (!top['JGP_COMS'][type]) {
            top['JGP_COMS'][type] = {}
        }

        if (type && !comId) {
            return top['JGP_COMS'][type]
        } else if (type && comId) {
            return top['JGP_COMS'][type][comId];
        }
    }

    static func(type, fnc) {
        if (!window['JGP_FNC']) {
            window['JGP_FNC'] = {}
        }
        if (!window['JGP_FNC'][type]) {
            window['JGP_FNC'][type] = []
        }
        if (fnc && Check.function(fnc)) {
            window['JGP_FNC'][type].push(fnc);
        } else {
            return window['JGP_FNC'][type]
        }
    }

    /**
     * 页面渲染后回调
     * @param fnc
     * @return {*}
     */
    static onloadFnc(fnc) {
        if (fnc) {
            Options.func('on_load', fnc)
        } else {
            return Options.func('on_load');
        }
    }

    static maskWrap(isTop) {
        if (isTop) {
            return top.window['JGP_COMS'][OPT.MASK.type]['jgp-mask']
        } else {
            return window['JGP_COMS'][OPT.MASK.type]['jgp-mask']
        }
    }

    static global(key, value) {
        if (key && value) {
            if (!top['JGP_GLOBAL']) top['JGP_GLOBAL'] = {};
            top['JGP_GLOBAL'][key] = value;
        } else if (key && !value) {
            return top['JGP_GLOBAL'][key];
        } else {
            return top['JGP_GLOBAL'];
        }
    }
}
Options.globalComponent();
