/**
 * 项目   jgp-front-pc
 * 作者   loufei
 * 时间   2019/3/19
 */
export default class Gl {
    static pileFocus(param) {
        const com = param.back.obj;
        let value = param.back.value;
        value = value.replace(' ', '').replace('K', '');
        let arr = value.split('+');
        com.val(Number(arr[0]) + Number(arr[1] / 1000));
    }

    static pileBlur(param) {
        const com = param.back.obj;
        let value = param.back.value + '';
        value = value.replace(' ', '');
        if (value.indexOf('.') !== -1) {
            let arr = value.split('.');
            com.val('K ' + arr[0] + '+' + Number(arr[1]) * 100);
        } else {
            com.val('K ' + value);
        }
    }
}
