<template>
    <div :id="_comId" :class="`${__class} ${styleClass}  col-${col} `" :style="{padding:padding,clear:'both'}" v-show="__show">

        <div :class="`label-wrap`" v-if="!!label" :style="`width: ${getLabelwidth}`" v-tooltip="{classes:['info'], content: tip,trigger:'hover'}">
            <label :for="`input-${_comId}`"><span>{{label}}</span><b v-if="getRequired">*</b></label>
        </div>

        <div :class="`tags-wrap ${focus} ${errors.has(name)?'is-danger':'' }`" v-on:focus="focus = 'focus'" v-on:blur="focus = ''"  :style="{width:`calc(100% - ${getLabelwidth})`}" tabindex="0">
            <div class="tag" v-for="(tag,tIndex) in getTags" :key="tIndex">
                <a href="javascript:void(0);">
                    <span>{{getLabel(tag)}}</span>
                    <i class="fa fa-remove" @click.stop="remove(tag)"></i>
                </a>
            </div>

            <input :disabled="getDisabled" type="hidden"
                   v-model="value"
                   @change="_value = $event.target.value"
                   :data-submit="getSubmit"
                   v-validate="getValid"
                   :name="name">

            <div v-if="getHasTool" class="tool-wrap">
                <slot name="tool"></slot>
            </div>
        </div>

    </div>

</template>
<script>
/**
     * Tags组件
     * @module jgp-area
     * @desc 多行文本 html textarea
     * @example <jgp-area _label="22222" _value="sssssss" _row="6"></jgp-area>
     */
import Common from "../../utils/common";
import Check from "check-types";
// TODO xxxxx替换成store所属模块
const event = {
    TOOL_ACTION: "onToolAction",
    CHANGE: "onTagChange",
    FOCUS: "onTagFocus",
    BLUR: "onTagBlur",
    CLICK: "onTagClick"
}
export default {
    data() {
        return {
            styleClass: 'inline-control',
            focus: ''
        }
    },
    model: {
        prop: '_value',
        event: 'change'
    },
    /**
         * @prop {String} _value 初始化值 多个值以逗号分隔
         * @prop {String} _label 标题
         * @prop {String} _label_width 标题宽度
         * @prop {String} _name
         * @prop {String} _placeholder 提示内容
         * @prop {String} _tip 长文本提示 在LABEL hover的时候显示
         * @prop {String} _col 所占列宽
         * @prop {String} _row 所占行数
         * @prop {String} _required 是否可用 {默认:false}
         * @prop {String} _disabled 是否禁用 {默认:false}
         * @prop {String} _padding 内边距 {默认(只负责 上、左、右):'5px 5px 0 5px'}
         */
    props: {
        '_value': String | Array | Number | Boolean,
        '_label': String,
        '_label_width': {
            type: String,
            default: '80px'
        },
        '_name': String,
        '_icon': String,
        '_col': {
            type: String | Number,
            default: '6'
        },
        '_row': {
            type: String,
            default: '1'
        },
        '_validator': {
            type: String
        },
        '_required': {
            type: String | Boolean,
            default: 'true'
        },
        '_disabled': {
            type: String,
            default: 'false'
        },
        '_placeholder': String,
        '_tip': String,
        '_padding': {
            type: String,
            default: '5px 5px 0'
        },
        '_hastool': {
            type: String,
            default: 'false'
        },
        '_display_option': {
            type: String,
            default: 'label'
        },
        '_list': {
            type: String | Object,
            default: '[]'
        },
        '_submit': {
            type: String | Boolean,
            default: 'true'
        }
    },
    computed: {
        getLabelwidth() {
            let formGroup = this.formGroup;
            let form = this.form;
            let parentLabelWidth = formGroup && formGroup.label_width ? formGroup.label_width : form && form.label_width ? form.label_width : undefined;
            return parentLabelWidth || this.label_width;
        },
        getRequired() {
            return Common.toBool(this.required)
        },
        getDisabled() {
            return Common.toBool(this.disabled)
        },
        getValid() {
            let str = "";
            if (this.getRequired && this.validator) {
                str = 'required|' + this.validator
            } else if (!this.getRequired && this.validator) {
                str = this.validator;
            } else if (this.getRequired) {
                str = "required"
            }
            if (this.disabled) {
                str = ""
            }
            return str;
        },
        form() {
            return this.getForm(this);
        },
        formGroup() {
            return this.getFormGroup(this);
        },
        getHasTool() {
            return Common.toBool(this.hastool)
        },
        getList() {
            if (Check.string(this.list) && Common.trim(this.list)) { return Common.toJson(this.list); } else { return this.list; }
        },
        getValue() {
            let vals = [];
            let value;
            if (Check.string(this.value) && Common.trim(this.value) !== '') {
                if (this.value.includes(',')) {
                    value = this.value.split(',');
                } else {
                    value = Common.toJson(this.value);
                }
            } else {
                value = this.value;
            }
            if (Check.array(value)) {
                vals = value;
            } else {
                vals = [value]
            }
            return vals.sort();
        },
        getTags() {
            let tags = [];
            if (this.getValue) {
                this.getList.forEach((item) => {
                    if (this.getValue.includes(item.value)) {
                        tags.push(item);
                    }
                })
            }
            return tags;
        },
        getSubmit() {
            return Common.toBool(this.submit) ? 'yes' : 'no'
        }
    },
    watch: {
        value: {
            handler: function (value) {
                this.$emit(event.CHANGE, { value: value });
                if (!!this.form && this.form.changeErrors) this.form.changeErrors(this.name, this.errors);
                if (!!this.form && this.form.setKeyValue) this.form.setKeyValue(this.name, value);
                if (this.onchange) Common.doFn(this.onchange, value);
            }
        }
    },
    methods: {
        getForm(com) {
            if (this.parentLevel > 10) {
                return undefined
            }
            let parent = com.$parent;
            if (!parent) return undefined;
            if (parent.cType === 'jgp-form' || parent.cType === 'jgp-query') {
                return parent;
            } else {
                this.parentLevel += 1;
                return this.getForm(parent)
            }
        },
        getFormGroup(com) {
            let parent = com.$parent;
            if (!parent) return undefined;
            if (this.parentLevel > 10 || parent.cType === 'jgp-form' || parent.cType === 'jgp-query') {
                return undefined
            }
            if (parent.cType === 'jgp-form-group') {
                return parent;
            } else {
                this.parentLevel += 1;
                return this.getFormGroup(parent)
            }
        },
        getLabel(item) {
            if (item) {
                if (this.display_option.indexOf(',') !== -1) {
                    const displayOptions = this.display_option.split(',');
                    return item[displayOptions[0]] + '【' + item[displayOptions[1]] + '】'
                } else {
                    return item[this.display_option];
                }
            }
        },
        remove(item) {
            this.value.splice(this.value.indexOf(item.value), 1);
        },
        /**
             * @name val
             * @function
             * @param value {String}
             * @desc  获取值 JGP.text('jgp-text-id').val()。设置值 JGP.text('jgp-text-id').val('123')
             */
        val(value, submit) {
            if (Common.checkInputValue(value)) {
                this.$set(this, 'value', value);
            } else {
                return this.value ? this.value : undefined;
            }
            if (typeof submit !== 'undefined') {
                this.$set(this, 'submit', submit);
            }
        },
        /**
             * @name reset
             * @function
             * @desc 清空已输入的内容
             */
        reset() {
            this.value = undefined;
        },
        valid() {
            return this.$validator.validate();
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {
    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {
        if (this.form) {
            this.form.els.push({ name: this.name, el: this });
        }
        this.$watch('focus', (newVal, oldVal) => {
            if (newVal === 'focus') {
                this.$emit(event.FOCUS, { newVal: newVal });
            } else if (newVal === '') {
                this.$emit(event.BLUR, { newVal: newVal });
            }
        })

        if (this.readonly) {
            this.addClass('readonly')
        }
        if (this.disabled) {
            this.addClass('disabled')
        }
    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {
        /* if (this.validControl)
             this.validControl.attach({name: this.name,rules:this.getValid}); */
    },
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
        let wrap = this.$el.querySelector('.tags-wrap');
        wrap.style.minHeight = 28 * parseInt(this.row) + 'px'
    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {
    },
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {
    },
    /* keep-alive 组件激活时调用。 */
    activated() {
    },
    /* keep-alive 组件停用时调用。 */
    deactivated() {
    },
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {
    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {
    }
}
</script>

<!--
<style lang="scss">
    @import "../../assets/css/jgp";
</style>
-->
