<template>
    <div :id="_comId" :class="__class" v-show="__show" @drop='doDrop' @dragover="doDragover" @dragenter='doDragEnter' @dragleave="doDragLeave">
        <jgp-panel>
            <div class="body">
                <ul :class="style" v-show="getList&&getList.length>0">
                    <li v-for="(item,index) in getList" :key="index" @click="doClick(index)">
                        <div href="javascript:void(0);" class="item">
                            <a href="javascript:void(0);">
                                <p class="title">{{item.title}}</p>
                                <p class="content" v-html="item.content"></p>
                                <input type="hidden" :value="item.key">
                                <div class="bg" :style="{'background-color':(item.type&&colors[item.type])||default_bg,'opacity':opacity}"></div>
                            </a>
                            <a href="javascript:void(0);" v-show="getCanRemove" class="remove" @click.stop="remove(index)">
                                <i class="fa fa-remove"></i>
                            </a>
                        </div>
                    </li>
                </ul>
                <div class="drop-area" ref="dropArea" v-show="getList&&getList.length==0&&getCanDrop">
                    <table v-show="getList&&getList.length==0">
                        <tr>
                            <td>
                                <span>{{disc}}</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </jgp-panel>
    </div>
</template>

<script>
import Common from '../../utils/common'
import Check from 'check-types'

/**
 * 文本组件
 *
 * @author 娄飞 【Gavin Lou】
 * @displayName JgpList
 */
export default {
    data() {
        return {}
    },
    /**
         * @prop {String} _list 数据
         * @prop {String} _wrapper 数据映射 本列表有{title,content,hidden,type}字段，次属性是设置数据与列表字段映射关系
         * @prop {String} _default_bg 默认列表项背景色 {默认:'gray'}
         * @prop {String} _opacity 背景透明度 {默认:'0.2'}
         * @prop {String} _color_wrapper 颜色映射 颜色是根据列表中的type字段值来映射背景颜色
         */
    props: {
        _disc: {
            type: String,
            default: '可将数据拖放到此处'
        },
        _can_remove: {
            type: String | Boolean,
            default: true
        },
        _list: String | Array,
        _wrapper: String,
        _color_wrapper: String,
        _default_bg: {
            type: String,
            default: 'gray'
        },
        _can_drop: {
            type: String | Boolean,
            default: false
        },
        _opacity: {
            type: String,
            default: '0.2'
        },
        _data_format: String,
        _unique: String | Boolean,
        _onclick: String
    },
    computed: {
        /* getSource: {
             get(){
             this.source = this.getList || [];
             return this.source;
             },
             set(record){
             this.source.push(record);
             }
             }, */
        getCanRemove() {
            return Common.toBool(this.can_remove);
        },
        getCanDrop() {
            return Common.toBool(this.can_drop);
        },
        dataStructure() {
            let structure;
            if (Check.string(this.wrapper) && this.wrapper !== '') {
                structure = [];
                this.wrapper.split(',').forEach(w => {
                    let kv = w.split(':');
                    structure.push([kv[0], kv[1]])
                })
            }
            return structure
        },
        colors() {
            let structure = {};
            if (this.color_wrapper && Check.string(this.color_wrapper) && this.color_wrapper !== '') {
                this.color_wrapper.split(',').forEach(w => {
                    let kv = w.split(':');
                    structure[kv[0]] = kv[1];
                })
            }
            return structure
        },
        getList() {
            let list = [];
            let _this = this;
            if (_this.list && Check.string(_this.list) && _this.list !== '') {
                list = Common.toJson(_this.list)
            } else if (Check.array(_this.list)) {
                list = _this.list;
            }
            if (!list) return list;
            if (_this.dataStructure) {
                let realList = [];
                let keys = [];
                list.forEach(item => {
                    let record = {}
                    for (let key in item) {
                        _this.getRealKey(key).forEach(realKey => {
                            record[realKey] = item[key]
                        })
                    }
                    record['bean'] = item;
                    if (_this.getUnique) {
                        if (!record['key']) {
                            console.error('对列表做唯一性校验，必须映射数据中的唯一属性映射为key 即 _mapper="...,key:id,..."');
                        } else {
                            if (keys.indexOf(record['key']) !== -1) {

                            } else {
                                keys.push(record['key']);
                                realList.push(record);
                            }
                        }
                    } else {
                        realList.push(record);
                    }
                })
                list = realList;
            }
            return list;
        },
        getUnique() {
            return Common.toBool(this.unique);
        }
    },
    methods: {
        getRealKey(dataKey) {
            let ks = [];
            this.dataStructure.forEach(keys => {
                if (keys[1] === dataKey) {
                    ks.push(keys[0])
                }
            })
            return ks;
        },
        doDrop(event) {
            event.preventDefault();
            let data = event.dataTransfer.getData('DRAG_DROP_DATA');
            if (!data) return;
            const DRAG_DROP_DATA = data ? JSON.parse(data) : undefined;
            const record = Common.doFn(this.data_format, DRAG_DROP_DATA);
            if (this.getList.map(s => s.key).includes(record.key)) {
                Common.warn('不能重复添加', 2);
                return;
            }
            this.getList.push(record)
        },
        doDragover (event) {
            event.preventDefault();
        },
        doDragEnter (event) {
            this.$refs.dropArea.className = 'drop-area hover';
            event.preventDefault();
        },
        doDragLeave (event) {
            this.$refs.dropArea.className = 'drop-area';
            event.preventDefault();
        },
        remove(index) {
            let list;
            let _this = this;
            if (_this.list && Check.string(_this.list) && _this.list !== '') {
                list = Common.toJson(_this.list)
            } else if (Check.array(_this.list)) {
                list = _this.list;
            }
            list = list || [];
            list.splice(index, 1);
            this.setList(list);
        },
        /**
             * @name getRecords
             * @function
             * @desc 获取列表中所有记录
             * @return [{record},{record}]
             */
        getAllRecords() {
            let records = [];
            for (let record of this.getList) {
                records.push(record.bean);
            }
            return records;
        },
        doClick(index) {
            if (this.onclick) {
                Common.doFn(this.onclick, this.getAllRecords()[index]['bean'])
            }
        },
        setList(list) {
            this.$set(this, 'list', list)
        },
        appendBefore(items) {
            let list;
            let _this = this;
            if (_this.list && Check.string(_this.list) && _this.list !== '') {
                list = Common.toJson(_this.list)
            } else if (Check.array(_this.list)) {
                list = _this.list;
            }
            list = list || [];
            items.forEach(item => {
                list.unshift(item)
            })
            this.setList(list);
        },
        appendAfter(items) {
            let list;
            let _this = this;
            if (_this.list && Check.string(_this.list) && _this.list !== '') {
                list = Common.toJson(_this.list)
            } else if (Check.array(_this.list)) {
                list = _this.list;
            }
            list = list || []
            items.forEach(item => {
                list.push(item)
            })
            this.setList(list);
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {
    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {
    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {
    },
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {
    },
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {
    },
    /* keep-alive 组件激活时调用。 */
    activated() {
    },
    /* keep-alive 组件停用时调用。 */
    deactivated() {
    },
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {
    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {
    }
}
</script>

<style>

</style>
