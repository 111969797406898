<template>
    <div :id="_comId" :class="__class">
        <div v-show="!isShowRemark">
            <jgp-btn v-for="(btn,index) in getBtns"
                     v-show="btn.mode.includes(mode) && btn.show"
                     :key="index"
                     :_disabled="btn.disabled || getFormEditFlag"
                     :_color="btn.color"
                     :_fn="__doAction(btn)">
                {{btn.tgText ? (btn.tgFlag ? btn.text : btn.tgText) : btn.text}}
            </jgp-btn>
        </div>
        <jgp-div v-show="isShowRemark" _row="['40','130']" _runit="px">
            <div slot="1-1">
                <jgp-btn v-for="(btn,index) in getBtns"
                         v-show="btn.mode.includes(mode) && btn.show"
                         :key="index"
                         :_disabled="btn.disabled || getFormEditFlag"
                         :_color="btn.color"
                         :_fn="__doAction(btn)">
                    {{btn.tgText ? (btn.tgFlag ? btn.text : btn.tgText) : btn.text}}
                </jgp-btn>
            </div>
            <jgp-form slot="2-1">
                <jgp-form-group _title="审批意见">
                    <jgp-area _row="3" _col="12" :_disabled="getFormEditFlag" ref="remark" ></jgp-area>
                </jgp-form-group>
            </jgp-form>
        </jgp-div>
    </div>
</template>

<script>
import Common from '../../utils/common'
import Task from '../../utils/task'

// const workflow_server_config =  Common.onceConfig('workflow') || {};

/**
     *
     * 项目   jgp-front-pc
     * 作者   loufei
     * 时间   2019/1/7
     */
export default {
    data() {
        return {
            show_remark: this.isShowRemark,
            savePromise: null,
            submitPromise: null,
            savedFlag: false,
            businessKey: null,
            pointPersons: [],
            procId: null,
            taskUtil: null,
            default_btns: {
                procPic: {
                    text: '查看流程图',
                    color: 'plain',
                    disabled: false,
                    show: true,
                    mode: ['create'],
                    fn: () => {
                        const _this = this;
                        if (!_this.checkCom) return
                        _this.__procPic();
                    }
                },
                // 保存
                save: {
                    text: '保存',
                    color: 'action',
                    disabled: false,
                    show: true,
                    mode: ['create', 'proc'],
                    fn: () => {
                        const _this = this;
                        if (!_this.checkCom) return
                        if (_this.save_fn) {
                            _this.__save();
                        }
                    }
                },
                // 提交
                submit: {
                    text: '提交并发起流程',
                    color: 'primary',
                    disabled: false,
                    show: true,
                    mode: ['create'],
                    fn: () => {
                        const _this = this;
                        if (!_this.checkCom) return
                        if (_this.save_fn) {
                            _this.__submit();
                        }
                    }
                },
                // 指定处理人
                point: {
                    text: '指定处理人',
                    color: 'primary',
                    disabled: false,
                    show: true,
                    multi: false,
                    mode: ['proc'],
                    fn: () => {
                        const _this = this;
                        if (!_this.checkCom) return
                        if (_this.select_assignee_callback) {
                            new Promise((resolve, reject) => {
                                Common.doFn(_this.select_assignee_callback, {
                                    resolve: resolve, reject: reject, procId: _this.getProcId, businessKey: _this.businessKey
                                })
                            }).then((persons) => {
                                _this.pointPersons = persons;
                            })
                        } else {
                            Common.error('未配置【_select_assignee_callback】');
                        }
                    }
                },
                // 传递
                pass: {
                    text: '传递',
                    color: 'primary',
                    disabled: false,
                    show: true,
                    mode: ['proc'],
                    fn: () => {
                        const _this = this;
                        if (!_this.checkCom) return
                        let hasSaveBtn = false;
                        for (let i = 0; i < this.getBtns; i++) {
                            let btn = this.getBtns[i];
                            if (btn.code === 'save') {
                                hasSaveBtn = true;
                            }
                        }
                        if (hasSaveBtn && !_this.savedFlag) {
                            Common.openAlert({
                                title: "请确认",
                                type: 'warn', // warn error info
                                autoHideTime: -1,
                                width: '330px',
                                height: '100px',
                                tools: [{
                                    title: '先保存后传递',
                                    fn: () => {
                                        _this.__doBtnFn('save');
                                        _this.savePromise.then(() => {
                                            _this.__doPass();
                                        }, () => {
                                            // 保存失败调用
                                            _this.setEnabled('save');
                                            _this.setEnabled('pass');
                                            console.warn('由于保存失败！所以无法传递！');
                                        })
                                    },
                                    color: 'primary'
                                }, {
                                    title: '直接传递',
                                    fn: () => {
                                        _this.__doPass();
                                    },
                                    color: 'primary'
                                }, {
                                    title: '取消',
                                    fn: () => {
                                        _this.setEnabled('pass');
                                    },
                                    icon: 'close',
                                    color: 'caution'
                                }],
                                content: '业务表单未保存，是否保存表单!'
                            })
                        } else {
                            Common.openAlert({
                                title: "请确认",
                                type: 'warn', // warn error info
                                autoHideTime: -1,
                                width: '330px',
                                height: '100px',
                                tools: [{
                                    title: '传递',
                                    fn: () => {
                                        _this.__doPass();
                                    },
                                    color: 'primary'
                                }, {
                                    title: '取消',
                                    fn: () => {
                                        _this.setEnabled('pass');
                                    },
                                    icon: 'close',
                                    color: 'caution'
                                }],
                                content: '是否确认传递工单!'
                            })
                        }
                    }
                },
                // 办理（认领）和取消办理
                claim: {
                    text: '办理',
                    tgText: '取消办理',
                    tgFlag: !this.getClaimState || this.getClaimState !== 'self',
                    color: 'royal',
                    disabled: false,
                    show: !!((!this.getClaimState || this.getClaimState === 'self')),
                    mode: ['proc'],
                    fn: () => {
                        const _this = this;
                        if (!_this.checkCom) return
                        if (_this.default_btns.claim.tgFlag) {
                            const remark = _this.$refs.remark.val();
                            this.taskUtil.claim(_this.getUserId, _this.getTaskId, remark || null, (result) => {
                                if (result.flag) {
                                    _this.default_btns.claim.tgFlag = false;
                                    // _this.btnStateControlForClaim();
                                    if (_this.on_claim_callback) {
                                        Common.doFn(_this.on_claim_callback, { flag: true, type: 'claim' });
                                    }
                                }
                            })
                        } else {
                            const remark = _this.$refs.remark.val();
                            this.taskUtil.unClaim(_this.getUserId, _this.getTaskId, remark || null, (result) => {
                                if (result.flag) {
                                    _this.default_btns.claim.tgFlag = true;
                                    // _this.btnStateControlForClaim();
                                    if (_this.on_claim_callback) {
                                        Common.doFn(_this.on_claim_callback, { flag: true, type: 'unClaim' });
                                    }
                                }
                            })
                        }
                    }
                },
                // 驳回
                back: {
                    text: '驳回',
                    color: 'caution',
                    disabled: false,
                    show: true,
                    mode: ['proc'],
                    fn: () => {
                        if (!this.checkCom) return
                        this.__back()
                    }
                },
                // 作废
                remove: {
                    text: '作废',
                    color: 'caution',
                    disabled: false,
                    show: true,
                    mode: ['proc']
                },
                // 填写办理意见
                remark: {
                    text: '填写办理意见',
                    color: 'highlight',
                    disabled: false,
                    show: true,
                    mode: ['proc'],
                    fn: () => {
                        this.show_remark = !this.show_remark
                    }
                }
            }
        }
    },
    props: {
        /**
         * 创建模式为 create<br>
         * 审批模式 proc
         */
        _mode: {
            type: String,
            default: 'create' // proc create
        },
        /**
         * 自定义按钮<br>
         * 选择范围 save submit(mode='create') point pass back remove remark claim
         */
        _custom_btn: {
            type: String, //
            default: ''
        },
        /**
         * @ignore
         */
        _server: String,
        /**
         * 流程定义ID
         */
        _def_key: String,
        /**
         * 禁用组件
         */
        _disabled: {
            type: String | Boolean,
            default: false
        },
        /**
         * @ignore
         */
        _multi: {
            type: String | Boolean,
            default: false
        },
        /** 业务保存方法 回调该方法时回传入 resolve reject 两个方法参数<br>
        业务保存成功后 调用 resolve({businessKey:'xxxxx'})<br>
        业务保存失败后 调用 reject(); */
        _save_fn: String,
        /** 提交之前回调 回调该方法时回传入 resolve reject 两个方法参数 以及 procId taskId businessKey 参数<br>
         业务操作成功后 调用 resolve({key:value}); 调用此方法后程序才回继续提交<br>
         业务操作失败后 调用 reject();  调用此方法后程序终止提交 */
        _submit_before_callback: String,
        /**
		提交并发起流程《成功》回调 返回procId businessKey
		 */
        _submit_success_callback: String,
        /**
		提交并发起流程《失败》回调 返回procId businessKey errorMsg
		 */
        _submit_error_callback: String,
        /** 传递之前回调 回调该方法时回传入 resolve reject 两个方法参数 以及 procId taskId businessKey 参数<br>
        业务操作成功后 调用 resolve({key:value}); 调用此方法后程序才回继续提交<br>
        业务操作失败后 调用 reject();  调用此方法后程序终止提交 */
        _pass_before_callback: String,
        /**
         * 传递完成回调 不管成功与否都会调用 返回procId taskId businessKey
         */
        _pass_over_callback: String,
        /**
         * 传递 《成功》 回调 返回procId taskId businessKey
         */
        _pass_success_callback: String,
        /**
         *  传递 《失败》 回调 返回procId taskId businessKey errorMsg
         */
        _pass_error_callback: String,
        /**
         * 指定会签人员 回调该方法时回传入 resolve reject 两个方法参数 以及 procId taskId businessKey 参数<br>
         * 选择人员后以人员数组形式 调用resolve([id1,id2,...]);<br>
         * 若不选择或停止 调用 reject();
         */
        _select_assignee_callback: String,
        /**
         * 确认办理回调 回调该方法时会传入 {flag:true} flag=true 为办理成功 flag=false 为办理失败
         */
        _on_claim_callback: String,
        /**
         * 驳回之前回调 回调该方法时回传入 resolve reject 两个方法参数 以及 procId taskId businessKey 参数
         */
        _back_before_callback: String,
        /**
         * 驳回成功
         */
        _back_over_callback: String
    },
    computed: {
        checkCom() {
            try {
                if (this.mode === 'create' && !this.def_key) {
                    console.error('流程组件', '组件未设置【未设置【 _def_key 】');
                } else {
                    return true;
                }
            } catch (e) {
                return false;
            }
        },
        server_props() {
            return Common.onceConfig('workflow');
        },
        getBusinessKey() {
            return this.businessKey || (this.server_props ? this.server_props.businessKey : null);
        },
        getProcId() {
            return this.server_props && this.server_props.procId ? this.server_props.procId : this.procId;
        },
        getTaskId() {
            return this.server_props ? this.server_props.taskId : null;
        },
        getUserId() {
            return this.server_props ? this.server_props.userId : null;
        },
        getClaimState() {
            return this.server_props ? this.server_props.claimState : null;
        },
        getDefKey() {
            return this.def_key;
        },
        getFormEditFlag() {
            var disabled = Common.toBool(this.disabled);
            var formEditFlag = this.server_props ? !this.server_props.formEditFlag : null;
            return formEditFlag != null ? !formEditFlag : Common.checkInputValue(disabled) ? disabled : true;
        },
        getMulti() {
            return Common.toBool(this.multi);
        },
        getBtns() {
            let btns = [];
            if (this.custom_btn) {
                const _btns = this.custom_btn.split(',');
                _btns.forEach(b_key => {
                    b_key = Common.trim(b_key);
                    let _b = this.default_btns[b_key];
                    if (_b) {
                        _b['code'] = b_key;
                        if (_b) btns.push(_b);
                    }
                })
            } else {
                for (var b_key in this.default_btns) {
                    let _b = this.default_btns[b_key];
                    if (_b) {
                        _b['code'] = b_key;
                        if (_b) btns.push(_b);
                    }
                }
            }
            return btns;
        },
        isShowRemark() {
            let flag = false;
            if (!this.custom_btn) {
                flag = false;
            } else {
                for (var i = 0; i < this.getBtns.length; i++) {
                    if (this.getBtns[i].code === 'remark') {
                        flag = true;
                    }
                }
            }
            return flag;
            // return true;
        },
        getShowBtn() {
            const _this = this;
            return _this.getBtns.filter(btn => {
                return btn.mode.includes(_this.mode)
            })
        }
    },
    methods: {
        setDisabled(btnName) {
            this.default_btns[btnName].disabled = true;
        },
        setEnabled(btnName) {
            this.default_btns[btnName].disabled = false;
        },
        __doAction(btn) {
            if (btn.fn) {
                return () => {
                    Common.doFn(btn.fn);
                }
            }
            // console.log('action::',btn);
        },
        __doBtnFn(btnName) {
            const fn = this.default_btns[btnName].fn;
            if (fn) fn();
        },
        __procPic() {
            this.taskUtil.showProcessPicBeforeStart(this.def_key, {
                width: '700px',
                height: '350px'
            })
        },
        __save() {
            const _this = this;
            let startFlag = !this.getBusinessKey;
            _this.setDisabled('save');
            _this.setDisabled('submit');
            let params;
            _this.savePromise = new Promise((resolve, reject) => {
                params = {
                    resolve: resolve, reject: reject
                }
                Common.doFn(_this.save_fn, params);
            });
            _this.savePromise.then((args) => {
                if (_this.mode === 'create') {
                    if (args && args.businessKey) {
                        _this.businessKey = args.businessKey;
                        console.log('已获取业务主键:', args.businessKey);
                    } else {
                        console.error('未返回业务主键!!');
                    }
                }
                _this.savedFlag = true;
                if (startFlag) {
                    delete args['businessKey'];
                    this.taskUtil.start(_this.getUserId, _this.getDefKey, _this.businessKey, args, (startResult) => {
                        if (startResult.flag) {
                            _this.procId = startResult.data.procId;
                            _this.setEnabled('submit');
                        } else {
                            params.reject('启动流程失败！');
                        }
                    })
                }
            }, () => {
                _this.setEnabled('save');
                _this.setEnabled('submit');
                console.warn('业务表单保存失败!!');
            });
        },
        __submit() {
            const _this = this;
            _this.setDisabled('submit');
            let params;
            _this.submitPromise = new Promise((resolve, reject) => {
                params = { resolve, reject }
            });

            const doSubmit = () => {
                // 保存成功调用
                // 1、启动流程
                /* Task.start(_this.getUserId, _this.getDefKey, _this.businessKey, null, (startResult) => {
                     if (startResult.flag) {

                     } else {
                     params.reject('启动流程失败！');
                     }
                     }) */

                let submitBeforePromise;
                if (_this.submit_before_callback) {
                    submitBeforePromise = new Promise((resolve, reject) => {
                        let params = {
                            procId: _this.getProcId,
                            businessKey: _this.businessKey,
                            resolve: resolve,
                            reject: reject
                        }
                        Common.doFn(_this.submit_before_callback, params);
                    });
                }

                if (submitBeforePromise) {
                    submitBeforePromise.then((jsonVariables) => {
                        // 启动成功后发起流程 由申请人走到下一个审批人
                        this.taskUtil.submit(_this.getUserId, _this.getProcId, jsonVariables, (submitResult) => {
                            if (submitResult.flag) {
                                params.resolve();
                            } else {
                                params.reject('提交失败！');
                            }
                        })
                    }, () => {
                        console.warn('提交失败！');
                        params.reject('启动流程失败！');
                    })
                } else {
                    // 启动成功后发起流程 由申请人走到下一个审批人
                    this.taskUtil.submit(_this.getUserId, _this.getProcId, null, (submitResult) => {
                        if (submitResult.flag) {
                            params.resolve();
                        } else {
                            params.reject('提交失败！');
                        }
                    })
                }
            }

            if (!_this.savedFlag) {
                _this.__doBtnFn('save');
                _this.savePromise.then(() => {
                    doSubmit();
                }, () => {
                    // 保存失败调用
                    _this.setEnabled('submit');
                    params.reject('由于保存失败！所以无法提交！');
                })
            } else {
                doSubmit();
            }

            _this.submitPromise.then(() => {
                Common.alert('提交成功', 2);
                if (_this.submit_success_callback) {
                    Common.doFn(_this.submit_success_callback, {
                        procId: _this.getProcId,
                        businessKey: _this.businessKey
                    })
                }
            }, (error) => {
                _this.setEnabled('submit');
                Common.error(error);
                console.warn(error);
                if (_this.submit_error_callback) {
                    Common.doFn(_this.submit_error_callback, {
                        procId: _this.getProcId,
                        businessKey: _this.businessKey,
                        errorMsg: error
                    })
                }
            })
        },
        __doPass() {
            const _this = this;
            _this.setDisabled('pass');
            const doPass = (promise, params) => {
                const remark = _this.$refs.remark.val();
                if (this.pointPersons) {
                    if (!params) params = {};
                    params['persons'] = this.pointPersons;
                }
                if (params) {
                    this.taskUtil.passWithVar(_this.getUserId, _this.getProcId, _this.getTaskId, remark || null, params || null, (result) => {
                        if (result.flag) {
                            promise.resolve()
                        } else {
                            promise.reject(result.msg)
                        }
                    })
                } else {
                    this.taskUtil.pass(_this.getUserId, _this.getProcId, _this.getTaskId, remark || null, (result) => {
                        if (result.flag) {
                            promise.resolve()
                        } else {
                            promise.reject(result.msg)
                        }
                    })
                }
            }
            let passBeforePromise;
            if (_this.pass_before_callback) {
                passBeforePromise = new Promise((resolve, reject) => {
                    let params = {
                        procId: _this.getProcId,
                        businessKey: _this.businessKey,
                        resolve: resolve,
                        reject: reject
                    }
                    Common.doFn(_this.pass_before_callback, params);
                });
            }

            let promise;
            let checkPassPromise = new Promise((resolve, reject) => {
                promise = {
                    resolve: resolve, reject: reject
                }
            });

            if (passBeforePromise) {
                passBeforePromise.then((params) => {
                    doPass(promise, params);
                }, () => {
                    _this.setEnabled('pass');
                    console.warn('传递操作已经被业务程序终止！')
                })
            } else {
                doPass(promise);
            }

            var args = {
                procId: _this.getProcId,
                businessKey: _this.businessKey
            }
            checkPassPromise.then(() => {
                Common.alert('传递成功', 2);
                if (_this.pass_success_callback) Common.doFn(_this.pass_success_callback, args)
            }, (error) => {
                _this.setEnabled('pass');
                Common.error('传递失败！');
                console.warn('传递失败！', error);
                args['errorMsg'] = error;
                if (_this.pass_error_callback) Common.doFn(_this.pass_error_callback, args)
            }).then(() => {
                if (_this.pass_over_callback) Common.doFn(_this.pass_over_callback, args)
            })
        },
        __back() {
            const _this = this;
            const remark = _this.$refs.remark.val();
            _this.setDisabled('pass');
            if (_this.back_before_callback) {
                const backBeforePromise = new Promise((resolve, reject) => {
                    let params = {
                        procId: _this.getProcId,
                        businessKey: _this.businessKey,
                        resolve: resolve,
                        reject: reject
                    }
                    Common.doFn(_this.back_before_callback, params);
                })
                backBeforePromise.then((target) => {
                    if (target) {
                        this.taskUtil.backTarget(_this.getUserId, _this.getProcId, _this.getTaskId, target, remark || null, (result) => {
                            if (!result.flag) {
                                _this.setEnabled('pass');
                            } else {
                                Common.alert('驳回成功！');
                                _this.setDisabled('back');
                            }
                            if (_this.back_over_callback) {
                                Common.doFn(_this.back_over_callback, result)
                            }
                        })
                    } else {
                        this.taskUtil.back(_this.getUserId, _this.getProcId, _this.getTaskId, remark || null, (result) => {
                            if (!result.flag) {
                                _this.setEnabled('pass');
                            } else {
                                Common.alert('驳回成功！');
                                _this.setDisabled('back');
                            }
                            if (_this.back_over_callback) {
                                Common.doFn(_this.back_over_callback, result)
                            }
                        })
                    }
                }).catch(() => {
                    _this.setEnabled('pass');
                })
            } else {
                this.taskUtil.back(_this.getUserId, _this.getProcId, _this.getTaskId, remark || null, (result) => {
                    if (!result.flag) {
                        _this.setEnabled('pass');
                    } else if (_this.back_over_callback) {
                        Common.doFn(_this.back_over_callback)
                    }
                })
            }
        },
        btnStateControlForClaim() {
            const btns = this.getShowBtn.filter(b => {
                return b.code === 'claim'
            });

            if (btns.length > 0) {
                for (let i = 0; i < this.getShowBtn.length; i++) {
                    const btn = this.getShowBtn[i];
                    if (btn.code !== 'claim') {
                        if (this.default_btns.claim.tgFlag) {
                            this.setDisabled(btn.code);
                        } else {
                            this.setEnabled(btn.code);
                        }
                    }
                }
            }
        },
        setDefKey(key) {
            this.$set(this, 'def_key', key);
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {
    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {
        this.taskUtil = new Task(this.server);
        // this.btnStateControlForClaim();
    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {
    },
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
        if (this.taskId) {
            this.taskUtil.getCommit(this.taskId, (result) => {
                this.$refs.remark.val(result.data);
            })
        }
    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {
    },
    /*
	由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

	当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
	然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
	用计算属性或 watcher 取而代之。

	注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
	绘完毕，可以用 vm.$ nextTick 替换掉 updated：
	*/
    updated() {
    },
    /* keep-alive 组件激活时调用。 */
    activated() {
    },
    /* keep-alive 组件停用时调用。 */
    deactivated() {
    },
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {
    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {
    }
}
</script>

<style>

</style>
