<template>
    <div :class="__class" :id="_comId">
        <jgp-layout-border-item v-if="!!getNorth" ref="layout-north" :key="'north'" :_region="'north'" :_holder="getNorth.holder" :_style="northStyle" @collapse="collapse">
            <slot name="north"></slot>
        </jgp-layout-border-item>
        <div class="middle" :style="middleStyle"><!--ver垂直-->
            <jgp-layout-border-item v-if="!!getWest" ref="layout-west" :key="'west'" :_region="'west'" :_holder="getWest.holder" :_style="westStyle" @collapse="collapse">
                <slot name="west"></slot>
            </jgp-layout-border-item>
            <jgp-layout-border-item v-if="!!getCenter" ref="layout-center" :key="'center'" :_region="'center'" :_holder="getCenter.holder" :_style="centerStyle" @collapse="collapse">
                <slot name="center"></slot>
            </jgp-layout-border-item>
            <jgp-layout-border-item v-if="!!getEast" ref="layout-east" :key="'east'" :_region="'east'" :_holder="getEast.holder" :_style="eastStyle" @collapse="collapse">
                <slot name="east"></slot>
            </jgp-layout-border-item>
        </div>
        <jgp-layout-border-item v-if="!!getSouth" ref="layout-south" :key="'south'" :_region="'south'" :_holder="getSouth.holder" :_style="southStyle" @collapse="collapse">
            <slot name="south"></slot>
        </jgp-layout-border-item>
    </div>
</template>

<script>
import Common from '../../utils/common'

import JgpLayoutBorderItem from './JgpLayoutBorderItem.vue'

/**
 * 东西南北中布局
 * 充满父级，分为东、西、南、北、中五个空间
 *
 * @author 娄飞 【Gavin Lou】
 * @displayName JgpLayoutBorder
 */
export default {
    components: { JgpLayoutBorderItem },
    data() {
        return {
            toggleState: {
                north: {},
                west: {},
                east: {},
                center: {},
                bottom: {}
            },
            middleStyle: {},
            center: {},
            border: '10px'
        }
    },
    props: {
        /**
         * 北（顶部）_north="{'height':'80px'}"
         */
        "_north": String,
        /**
         * 西（左侧）_west="{'width':'80px','holder':true}"
         */
        "_west": String,
        /**
         * 东（右侧）_east="{'width':'80px','holder':true}"
         */
        "_east": String,
        /**
         * 南（下部）_south="{'height':'80px'}"
         */
        "_south": String
    },
    computed: {
        getNorth() {
            return Common.toJson(this.north);
        },
        getWest: {
            get: function () {
                return Common.toJson(this.west);
            },
            set: function (value) {
                this.west = JSON.stringify(Object.assign({}, this.getWest, value))
            }
        },
        getEast: {
            get: function () {
                return Common.toJson(this.east);
            },
            set: function (value) {
                this.east = JSON.stringify(Object.assign({}, this.getEast, value))
            }
        },
        getCenter() {
            return this.center;
        },
        getSouth() {
            return Common.toJson(this.south);
        },
        northStyle() {
            return this.$slots['north'] && this.getNorth ? this.getNorth : {};
        },
        westStyle() {
            return this.$slots['west'] && this.getWest ? this.getWest : {};
        },
        eastStyle() {
            return this.$slots['east'] && this.getEast ? this.getEast : {};
        },
        centerStyle() {
            return this.$slots['center'] && this.getCenter ? this.getCenter : {};
        },
        southStyle() {
            return this.$slots['south'] && this.getSouth ? this.getSouth : {};
        }
    },
    created() {

    },
    mounted() {
        this.$nextTick(() => {
            this.resize()
        })
    },
    updated() {
        this.$nextTick(function () {
            this.resize()
        })
    },
    methods: {
        getRegion(region) {
            return this[region];
        },
        collapse(region, flag) {
            switch (region) {
                case 'west' :
                    if (flag) {
                        this.toggleState['west'] = Object.assign({}, this.getWest);
                        this.$set(this, 'getWest', { 'width': '0px' });
                    } else {
                        this.$set(this, 'getWest', { 'width': this.toggleState['west'].width });
                    }
                    break
               /*  case 'east' :
                    if (!flag && animate === 'leave') {
                        this.toggleState['east'] = Object.assign({}, this.getEast);
                        this.$set(this, 'getEast', { 'width': '0px' });
                    } else if (flag && animate === 'enter') {
                        this.$set(this, 'getEast', { 'width': this.toggleState['east'].width });
                    }
                    break */
            }
            this.resize();
        },
        toggleWest(flag) {
            this.$refs['layout-west'].collapse(!flag);
        },
        resize() {
            let northHeight = this.northStyle['height'] || '0px';
            let southHeight = this.southStyle['height'] || '0px';
            let westWidth = this.westStyle['width'] || '0px';
            let eastWidth = this.eastStyle['width'] || '0px';

            this.$set(this.middleStyle, 'top', this.$slots.north ? northHeight : '0px');
            this.$set(this.middleStyle, 'bottom', this.$slots.south ? southHeight : '0px');

            // 中间只有一栏的情况
            if (this.west && !this.east && !this.center) {
                this.$set(this.westStyle, 'width', `100%`);
            } else if (!this.west && this.east && !this.center) {
                this.$set(this.eastStyle, 'width', `100%`);
            } else if (!this.west && !this.east && this.center) {
                this.$set(this.centerStyle, 'width', `100%`);
            }

            // 中间有两栏的情况
            if (this.west && this.east && !this.center) {
                this.$set(this.eastStyle, 'width', `calc(100% - ${westWidth} - ${this.border})`);
            } else if (!this.west && this.east && this.center) {
                this.$set(this.centerStyle, 'left', `0px`);
                this.$set(this.centerStyle, 'width', `calc(100% - ${eastWidth})`);
            } else if (this.west && !this.east && this.center) {
                if (Common.trim(westWidth) === '0px') {
                    this.$set(this.centerStyle, 'left', '0px');
                    this.$set(this.centerStyle, 'width', '100%');
                } else {
                    this.$set(this.centerStyle, 'left', `calc(${westWidth} + ${this.border})`);
                    this.$set(this.centerStyle, 'width', `calc(100% - ${westWidth} - ${this.border})`);
                }
            }

            if (this.west && this.east && this.center) {
                this.$set(this.centerStyle, 'left', `calc(${westWidth} + ${this.border})`);
                this.$set(this.centerStyle, 'width', `calc(100% - ${westWidth} - ${eastWidth} - 2*${this.border})`);
            }
        }
    }
}
</script>
