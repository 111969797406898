<template>
    <div :id="_comId" :class="__class" :style="{'z-index': zindex}">
        <div class="mask" v-if="hasMask" :style="{'z-index': 1}"></div>
        <transition
            :leave-active-class="`animated fadeOutUp`"
            :enter-active-class="`animated bounceInDown`">
            <div class="alert" v-show="showFlag"
                 :style="{'z-index': 2,left:getLeft}">
                <jgp-panel :class="alert.type" v-if="alert"
                           :_width="getW"
                           :_height="getH"
                           _default_tools="[]"
                           :_tools="getTools"
                           :_title="alert.title">
                    <table :style="{'width':'100%','height':'100%','text-align': 'center'}">
                        <tr>
                            <td><p v-html="alert.content"></p></td>
                        </tr>
                        <tr>
                            <td>
                                <button v-if="!alert.tools" type="button" @click="close"
                                        class="button button-tiny">
                                    <i class="fa fa-check"></i> 知道了！
                                </button>
                                <button v-else-if="alert.tools" v-for="(tool,fnIndex) in alert.tools" :key="fnIndex" type="button" @click.stop="doFn(fnIndex)" style="margin-right: 5px;"
                                        :class="`button ${tool.color?('button-'+tool.color):(fnIndex == 0?'button-highlight-flat':'')} button-tiny ${tool.class}`">
                                    <i :class="`fa fa-${tool.icon}`"></i> {{tool.title}}
                                </button>
                            </td>
                        </tr>
                    </table>
                </jgp-panel>
            </div>
        </transition>
    </div>
</template>

<script>
import Common from '../../utils/common'
/*
     * 项目   vue
     * 作者   loufei
     * 时间   2018/2/22
     */
export default {
    data() {
        return {
            width: '230px',
            height: '115px',
            showFlag: false,
            timerTask: null,
            activeFn: null
        }
    },
    props: [
        '_zindex',
        '_alert'
    ],
    computed: {
        getTop() {
            return `0px`
        },
        getLeft() {
            return `calc((100% - ${this.getW}) / 2)`
        },
        getTools() {
            return [{
                text: '',
                icon: 'dot-circle-o',
                fn: this.close
            }]
        },
        getW() {
            return this.alert.width ? this.alert.width : this.width;
        },
        getH() {
            return this.alert.height ? this.alert.height : this.height;
        },
        hasMask() {
            return !((this.alert && this.alert.mask === false));
        }
    },
    watch: {
        /* showFlag(flag){
                if (!flag) {
                    this.close();
                }
            } */
    },
    methods: {
        close() {
            if (this.timerTask) clearTimeout(this.timerTask);
            new Promise((resolve, reject) => {
                this.showFlag = false;
                this.timerTask = setTimeout(() => {
                    resolve();
                }, 200)
            }).then(() => {
                if (this.timerTask) clearTimeout(this.timerTask);
                Common.closeAlert();
            }).then(() => {
                if (this.activeFn && this.activeFn.fn) {
                    this.activeFn.fn()
                }
            });
        },
        doFn(fnIndex) {
            if (this.alert.tools && this.alert.tools[fnIndex]) {
                this.activeFn = this.alert.tools[fnIndex];
                this.close();
            }
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {
    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {
    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {
    },
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
        if (this.alert) {
            this.showFlag = true
        }
        if (this.alert && (this.alert.autoHideTime && this.alert.autoHideTime !== -1)) {
            this.timerTask = setTimeout(() => {
                this.close();
            }, this.alert.autoHideTime * 1000)
        }
    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {

    },
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {

    },
    /* keep-alive 组件激活时调用。 */
    activated() {
    },
    /* keep-alive 组件停用时调用。 */
    deactivated() {
    },
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {

    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {
    }
}
</script>

<style>

</style>
