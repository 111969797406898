<template>
    <div :id="_comId" :class="__class" v-show="__show">
        <transition
            :enter-active-class="`animated fadeInLeft`">
            <div v-show="bean.expend" class="tree-content-wrap">
                <div class="node-wrap root" v-if="bean.key == -1 && root">
                    <a href="javascript:void(0);">
                        <span v-show="getHasCheck"><input type="checkbox" :class="{'half-selected':!!bean.halfSelected}" :id="`check${bean.key}`" v-model="bean.selected"><label :for="`check${bean.key}`"></label></span>
                        <span @click="click({bean:{id:-1}})">{{root}}</span></a>
                </div>
                <ul :class="{'aux-line':children&&children.length>1}">
                    <li :class="{'node-wrap':true,'aux-line':children&&children.length==1}" :key="index" v-for="(child,index) in children">
                        <i class="y-line" v-if="children.length==1"></i>
                        <i class="x-line"></i>
                        <a :id="`node-`+child.key" href="javascript:void(0);" :class="`tree-node ${show_tools}-tools`">
                            <span v-show="getHasCheck"><input type="checkbox" :class="{'half-selected':!!child.halfSelected}" v-model="child.selected" :id="`check${child.key}`"><label :for="`check${child.key}`"></label></span>
                            <span @click="toggle(index)"><i :class="`fa fa-caret-${child.expend?'down':'right'}`"></i></span>
                            <span v-show="showIcon&&child.bean.icon"><i :class="`fa fa-${child.bean.icon}`"></i></span>
                            <span class="tree-node-text" @click="click(child)">{{child.title}}</span>
                            <span class="tree-tool" :key="tIndex" v-for="(tool,tIndex) in getTools" v-tooltip="tool.tip" @click="operateNode(tool.fn,child)"><i v-if="!!tool.icon" :class="`fa fa-${tool.icon}`"></i></span>
                        </a>
                        <jgp-tree :_onload="onload" :_root_key="root_key?root_key:_comId" :_single="single" :_auto_load="auto_load" :_selected_keys="selected_keys" :_tools="tools" :_onclick="onclick" :_check="check" :_icon="icon" :_lazy="lazy" :_expend="child.bean.expend" :bean="child"
                                  :_url="isLazy?url:undefined"></jgp-tree>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
/**
     * 表格组件
     * @module jgp-tree
     * @example  <jgp-tree _id="menuTree" _url="" _has_check="true" _root="系统菜单" _single="true"></jgp-tree>
     */
import Common from '../../utils/common'
export default {
    data() {
        return {
            selectedArr: []
        }
    },
    /**
         * @prop {String} _auto_load 是否自动加载 {默认:'true'}
         * @prop {String} _root 顶级名称 {默认:'根'}
         * @prop {String} _check 是否有选择框
         * @prop {String} _single 是否是单选  {默认:'false'} 在_has_check='true' 时生效
         * @prop {String} _icon 是否显示图标
         * @prop {String} _expend 是否全部展开
         * @prop {String} _show_tools {默认:'always'} 可选 always、hover
         * @prop {String} _tools 节点工具对象字符串数组 [{'icon':'edit','tip':'编辑','fn':'edit'},{'icon':'window-restore','fn':'addFields'}]，icon 为图标 同按钮中的icon，tip为提示信息，fn同按钮中的fn
         * @prop {String} _url 异步数据源
         * @prop {String} _ajax_param 异步提交参数
         * @prop {String} _lazy 懒加载 {默认:'true'}
         * @prop {String} _onclick 点击事件
         * @prop {String} _onload 数据加载完毕
         */
    props: {
        "bean": {
            type: Object,
            default() {
                return {
                    title: '根',
                    expend: true,
                    selected: false,
                    key: -1,
                    value: null,
                    children: null
                }
            }
        },
        "_root_key": null,
        "_auto_load": {
            type: String,
            default: 'true'
        },
        "_root": {
            type: String,
            default: "根"
        },
        "_single": {
            type: String,
            default: 'false'
        },
        "_check": {
            type: String,
            default: 'false'
        },
        "_icon": {
            type: String,
            default: 'true'
        },
        "_expend": {
            type: String,
            default: 'true'
        },
        "_tools": String,
        "_show_tools": {
            type: String,
            default: 'always' // always hover
        },
        "_url": {
            type: String,
            default: undefined
        },
        "_ajax_param": {
            type: String,
            default: "{'parentId': 'null'}"
        },
        "_lazy": {
            type: String,
            default: 'true'
        },
        "_selected_keys": String | Array,
        "_onclick": String | Function,
        "_onload": String | Function

    },
    computed: {
        children() {
            let children = this.bean.children;
            if (this.selected_keys) {
                const selected_keys = Common.toJson(this.selected_keys);
                if (selected_keys && selected_keys.length > 0 && children) {
                    children.forEach(child => {
                        if (selected_keys.includes(child.key)) {
                            child.selected = true;
                        }
                    })
                }
            }
            return children;
        },
        isSingle() {
            return Common.toBool(this.single);
        },
        showIcon() {
            return Common.toBool(this.icon);
        },
        getHasCheck() {
            return Common.toBool(this.check);
        },
        isExpend() {
            return Common.toBool(this.expend);
        },
        isLazy() {
            return Common.toBool(this.lazy);
        },
        isAutoLoad() {
            return Common.toBool(this.auto_load);
        },
        getTools() {
            return Common.toJson(this.tools)
        }
    },
    methods: {
        toggle(index) {
            this.$set(this.bean.children[index], 'expend', !this.bean.children[index].expend);
        },
        /**
             * @function
             * @param ajaxParam 键值对参，数用于传递后台
             * @desc 手动请求加载数据
             */
        load(ajaxParam) {
            if (!ajaxParam) {
                ajaxParam = {}
            }
            ajaxParam = Object.assign({
                parentId: this.bean.key ? this.bean.key : -1,
                lazy: this.isLazy
            }, ajaxParam)
            this.$nextTick(() => {
                Common.get(this.url, ajaxParam, (result) => {
                    if (this.isSingle) {
                        for (let r of result) {
                            r.selected = false;
                        }
                    }
                    this.$set(this.bean, 'children', result);
                    this.onLoaded();
                })
            })
        },
        canLoad() {
            if (this.url && this.isAutoLoad) {
                this.load(Object.assign(Common.toJson(this.ajax_param), {
                    parentId: this.bean.key ? this.bean.key : -1,
                    lazy: this.isLazy
                }));
            }
        },
        operateNode(fn, node) {
            Common.doFn(fn, node.bean, window);
        },
        onLoaded() {
            if (this.onload) {
                this.$nextTick(() => {
                    Common.doFn(this.onload, this.bean);
                })
            }
        },
        checkHalf(state) {
            let target = this.$parent;
            if (target.cType === 'jgp-tree' && target.children && target.children.length !== 0) {
                let childSelectedCount = 0
                let childHalfSelectedCount = 0
                for (let child of target.children) {
                    if (child.selected) childSelectedCount++
                    if (child.halfSelected) childHalfSelectedCount++
                }

                if (childHalfSelectedCount === 0 && childSelectedCount === 0) {
                    target.bean.selected = false;
                    target.bean.halfSelected = false;
                } else if (childHalfSelectedCount > 0 || childSelectedCount < target.children.length) {
                    target.bean.selected = true;
                    target.bean.halfSelected = true;
                } else if (childHalfSelectedCount === 0 && childSelectedCount === target.children.length) {
                    target.bean.selected = true;
                    target.bean.halfSelected = false;
                } else if (childHalfSelectedCount > 0 && childSelectedCount === 0) {
                    target.bean.selected = true;
                    target.bean.halfSelected = true;
                }
            }
            if (target.checkHalf) target.checkHalf()
        },
        /**
             * @name selectAll
             * @function
             * @param state {String} true/false {true:全选,false:取消全选}
             * @desc 全选|全部取消选择
             */
        selectAll(state) {
            if (this.children && this.children.length > 0) {
                for (let child of this.$children) {
                    child.bean.selected = state
                }
                this.bean.selected = state;
            }
        },
        clearParent() {
            if (this.$parent.cType === 'jgp-tree') {
                this.$parent.clearParent();
            }
            for (let child of this.$children) {
                child.bean.selected = false;
            }

            if (this.bean.key === -1) {
                this.bean.selected = false;
            }
        },
        clearChildren() {
            for (let child of this.$children) {
                if (child.$children.length > 0) {
                    child.clearChildren()
                }
                for (let child of this.$children) {
                    child.bean.selected = false;
                }
            }
        },
        collectSelected() {
            let selectedArr = [];
            if (this.children && this.children.length > 0) {
                for (let child of this.children) {
                    if (child.selected) {
                        selectedArr.push(child.bean)
                    }
                }
            }
            if (this.$children && this.$children.length > 0) {
                for (let $c of this.$children) {
                    let items = $c.collectSelected();
                    for (let it of items) {
                        selectedArr.push(it);
                    }
                }
            }
            if (!this.bean.key && this.bean.selected) {
                selectedArr.push(this.bean);
            }
            return selectedArr;
        },
        /**
             * @name getSelected
             * @function
             * @desc 获取所有选择项，若没有 返回 -1
             */
        getSelected() {
            let allSelected = this.collectSelected();
            return allSelected.length === 0 ? -1 : allSelected;
        },
        /**
             * @name setSelected
             * @function
             * @param selectedKeys 传一个key 或 多个key
             * @desc 设置选中数据
             */
        setSelected(selectedKeys) {
            this.selected_keys = selectedKeys;
        },
        click(item) {
            let currentClick = null;
            if (this.root_key) {
                currentClick = document.querySelector('#' + this.root_key + ' .node-current-click');
            } else {
                currentClick = document.querySelector('#' + this._comId + ' .node-current-click');
            }

            if (currentClick) {
                currentClick.classList.remove('node-current-click');
            }
            let targetNode = document.querySelector('#node-' + item.key + ' .tree-node-text');
            if (targetNode && targetNode.classList && targetNode.classList.length > 0) { targetNode.classList.add('node-current-click'); }
            if (this.onclick) {
                Common.doFn(this.onclick, item);
            }
        }

    },
    watch: {
        expend: function () {
            if (this.isExpend && !this.bean.children) {
                this.canLoad();
            }
        },
        'bean.selected'(state) {
            if (this.isSingle) {
                if (state) {
                    this.clearParent();
                    this.clearChildren();
                    this.bean.selected = true;
                }
            } else {
                if (!this.bean.halfSelected || !state) { this.selectAll(state); }
                this.checkHalf(state);
            }
            let selectedArr = this.selectedArr;
            if (state) {
                selectedArr.push(this.bean)
            } else {
                selectedArr.splice(selectedArr.indexOf(this.bean), 1)
            }
        }
    },
    created() {
        if (this.url && this.isExpend) {
            this.canLoad();
        }
        this.$set(this.bean, 'halfSelected', false);
    },
    mounted() {
        this.checkHalf();
        this.bean.expend = this.expend;
    },
    updated() {

    }
}
</script>
