/**
 * 项目   jgp-front-pc
 * 作者   loufei
 * 时间   2019/4/3
 */
import axios from "axios";
import qs from 'qs';

const DEBUG = process.env.NODE_ENV !== 'production';

export default class Rest {
    axios;
    axiosConfig;
    static FORM = 'application/x-www-form-urlencoded;charset=UTF-8';
    static JSON = 'application/json;charset=UTF-8';
    static FILE = 'multipart/form-data;charset=UTF-8';

    error;
    success;
    complete;

    /**
     * 请求构造器
     *
     * @param action    请求地址
     * @param method    请求方法 post put patch get delete
     * @param dataType  请求body中所携带的数据类型 可选 json form file
     * @param urlParams url参数 {a:2,s:1} 请求的时候会拼接在url上 a=2&s=1
     * @param postData  post put patch 等请求方式body中携带的参数
     * @param success   成功回调函数
     * @param error     失败回调函数
     * @param complete  完成回调函数
     */
    constructor({ action, method, dataType = "json", urlParams, postData, success, error, complete }) {
        this.dataType = dataType.toUpperCase();
        this.axiosConfig = {
            url: action,
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            data: postData || {},
            params: urlParams || {},
            method: method,
            paramsSerializer: function (params) {
                return qs.stringify(params, { arrayFormat: 'brackets' })
            }
        }

        this.success = success;
        this.error = error;
        this.complete = complete;
    }

    addData(key, value) {
        this.axiosConfig.data[key] = value;
    }

    addAllData(data) {
        this.axiosConfig.data = Object.assign({}, this.axiosConfig.data, data);
    }

    api = (method) => {
        this.axiosConfig['method'] = method;
        this.axios = axios.create(this.axiosConfig);
        this.axios.interceptors.request.use((config) => {
            switch (this.dataType) {
                case 'FORM':
                    config.headers['Content-Type'] = Rest.FORM;
                    config.data = qs.stringify(config.data);
                    break;
                case 'JSON':
                    let data = {};
                    if (config.data) {
                        for (let key in config.data) {
                            if (key.indexOf('.') !== -1) {
                                const keyArr = key.split('.');
                                if (!data[keyArr[0]]) {
                                    data[keyArr[0]] = {}
                                }
                                data[keyArr[0]][keyArr[1]] = config.data[key];
                            } else {
                                data[key] = config.data[key];
                            }
                        }
                        config.data = data;
                    }
                    config.headers['Content-Type'] = Rest.JSON;
                    break;
                case 'FILE':
                    config.headers['Content-Type'] = Rest.FILE;
                    break;
            }
            return config
        })
        this.axios.request().then((response) => {
            if (DEBUG) console.log('请求成功!', response);
            if (response.status === 200) {
                if (this.success) {
                    this.success(response.data, { status: response.status, statusText: response.statusText })
                }
            }
        }).catch((err) => {
            if (DEBUG) console.log('请求错误!', err);
            let response = err.response;
            if (response && this.error) {
                if (this.error) this.error(response.data, { status: response.status, statusText: response.statusText });
            } else {
                this.error();
            }
        }).finally(() => {
            if (DEBUG) console.log('请求完成!');
            if (this.complete) this.complete();
        });
    }

    put=() => {
        this.api('put');
    }

    post=() => {
        this.api('post');
    }
    patch=() => {
        this.api('patch');
    }

    get=() => {
        this.api('get');
    }

    delete=() => {
        this.api('delete');
    }
}
