<template>
    <div :class="`${__class} ${region}`" :style="style">
        <div style="width:100%;height:100%" v-show="flag">
            <slot></slot>
        </div>
        <div v-if="isHolder" :key="0" class="holder-wrap" v-show="!closed" @click.stop.prevent="collapse()">
            <i class="fa fa-angle-left"></i>
        </div>
        <div v-if="isHolder" :key="1" class="holder-wrap active" v-show="closed" @click.stop.prevent="collapse()">
            <i class="fa fa-angle-right"></i>
        </div>
    </div>

</template>

<script>
export default {
    data() {
        return {
            closed: false,
            flag: true
        }
    },
    props: ['_region', '_holder'],
    computed: {
        isHolder() {
            return !!this.holder;
        }
    },
    created() {
    },
    methods: {
        collapse(flag) {
            this.closed = flag !== undefined ? flag : !this.closed;
            if (this.closed) {
                this.$emit('collapse', this.region, this.closed);
            } else {
                this.$emit('collapse', this.region, this.closed);
            }
        },
        leaveCancelled() {
            this.flag = true;
        },
        enterCancelled() {
            this.flag = false;
        },
        afterLeave() {
            if (!this.flag) {
                this.$emit('collapse', this.region, this.flag, 'leave');
                this.closed = true;
            }
        },
        afterEnter() {
            if (this.flag) {
                this.$emit('collapse', this.region, this.flag, 'enter');
                this.closed = false;
            }
        }
    }
}
</script>
