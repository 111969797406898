/**
 * 项目   vue
 * 作者   loufei
 * 时间   2018/2/16
 */
import Check from "check-types";
import DATE_LIB from "date-fns";
import GET_FORM_DATA from "get-form-data";
import axios from "axios";
import qs from "qs";
import Constants from "./constants";
import Options from "./options";
import { MIME_TYPE } from "./mime";
import { exportExcelByQuery, exportGridData } from "./export";
axios.interceptors.request.use(function(config) {
    if (
        config.method.toUpperCase() === "POST" &&
        config.headers["Content-Type"] !== "application/json"
    ) {
        config.data = qs.stringify(config.data);
    }
    return config;
});
/**
 * @module Common
 * @alias module:my/shirt.wash
 */
/**
 * @class ./utils/Common
 * @summary 基于Vue的子组件规范实现，不可直接实例化
 * @example ssss
 */
export default class Common {
    static EMIT = Constants.EMIT;
    static DATE_FORMAT = Constants.FORMAT.DATE;
    static NUM_FORMAT = Constants.FORMAT.NUM;
    static UNITS = Constants.UNITS;
    static DATE = DATE_LIB;

    static exportExcelByQuery = exportExcelByQuery;
    static exportGridData = exportGridData;

    static trim = function(str) {
        return str.replace(/^\s\s*/, "").replace(/\s\s*$/, "");
    };

    static BASE =
        window.BASE && Common.trim(window.BASE) !== "/" ? window.BASE : "";

    static serverProps(key) {
        return Options.CONFIG.SERVER_PROP
            ? JSON.parse(Options.CONFIG.SERVER_PROP)[key]
            : null;
    }

    static onceConfig(key) {
        const onceConfig = Common.serverProps("onceConfig");
        return onceConfig ? onceConfig[key] : undefined;
    }

    static isObject(obj) {
        return Object.prototype.toString.call(obj) === "[object Object]";
    }

    static transformName(name) {
        if (!name) return;
        var c = name.charAt(0);
        if (c > "A" && c < "Z") {
            name = c.toLowerCase() + name.substr(1);
        }
        return name.replace(/([A-Z])/g, "-$1").toLowerCase();
    }

    static userAgent() {
        let ua = window.navigator.userAgent;
        let ret = "";
        if (/Firefox/g.test(ua)) {
            ua = ua.split(" ");
            ret = "Firefox|" + ua[ua.length - 1].split("/")[1];
        } else if (/MSIE/g.test(ua)) {
            ua = ua.split(";");
            ret = "IE|" + ua[1].split(" ")[2];
        } else if (/Opera/g.test(ua)) {
            ua = ua.split(" ");
            ret = "Opera|" + ua[ua.length - 1].split("/")[1];
        } else if (/Chrome/g.test(ua)) {
            ret = "Chrome|" + ua[ua.length - 2].split("/")[1];
            if (ret.split("|")[1] === "undefined") {
                ret = "Chrome|" + ua.substr(ua.lastIndexOf("Chrome/") + 7, 2);
            }
        } else if (/^apple\s+/i.test(navigator.vendor)) {
            ua = ua.split(" ");
            ret = "Safair|" + ua[ua.length - 2].split("/")[1];
        } else {
            ua = ua.split(" ");
            ret = "未知浏览器";
        }
        return ret;
    }

    static rndNum(n) {
        var rnd = "";
        for (var i = 0; i < n; i++) {
            rnd += Math.floor(Math.random() * 10);
        }
        return rnd;
    }

    static zIndex() {
        if (!window["JGP_Z_INDEX"]) window["JGP_Z_INDEX"] = 100;
        window["JGP_Z_INDEX"] += 1;
        return window["JGP_Z_INDEX"];
    }

    /**
     * 组件内方法调用
     * 可吊起组件外部页面方法
     * @param func function | string
     * @param args json字符串
     * @param target 指定作用域 在访问页面对象时起作用，如果为空 则首先寻找当前页面的func，若当前页面没有此func 则寻找父级页面。
     * 如果不为空则寻找target域下的func
     */
    static doFn(func, args, target, origin) {
        let fn = null;
        if (!func) return;
        if (Check.function(func)) {
            return func(args);
        } else if (Check.string(func)) {
            const index = func.indexOf(":");
            if (index <= 0) {
                fn = {
                    name: func
                };
            } else {
                fn = {
                    name: func.substring(0, index),
                    args: JSON.parse(
                        func.substring(index + 1).replace(/'/g, '"')
                    )
                };
            }
        } else if (Check.object(func)) {
            fn = func;
        }
        // let tar = target || window;
        let tar = origin || target || window;
        if (fn) {
            let fnn = {
                name: fn.name,
                args: {}
            };
            if (fn.args) {
                fnn["args"]["_back"] = fn.args;
            }
            if (args) {
                fnn["args"]["back"] = args;
            }

            /* if (!tar[fnn.name] && origin) {
                tar = origin;
            } */

            if (tar[fnn.name]) {
                if (!Check.function(tar[fnn.name])) {
                    console.warn(fnn.name + "::不是function");
                }
                return tar[fnn.name](fnn.args);
            }
        }
    }

    static getFormData(form, field) {
        const filterNames = [].slice
            .call(form.querySelectorAll("[data-submit=no]"))
            .map(item => {
                return item.name;
            });

        let data = Object.assign(
            {},
            GET_FORM_DATA(form, field, { trim: true })
        );

        for (let key in data) {
            if (
                key.lastIndexOf("_hasHidden") !== -1 ||
                filterNames.indexOf(key) !== -1
            ) {
                delete data[key];
            }
        }
        return data;
    }

    static httpForm(form, callback, errorCallback) {
        const data = Common.getFormData(form);
        Common.http(
            form.method,
            form.getAttribute("action") || form.action,
            data,
            callback,
            errorCallback,
            {}
        );
    }

    static sendForm(action, method, data, callback, errorCallback) {
        Common.http(
            method,
            action,
            qs.stringify(data),
            callback,
            errorCallback
        );
    }

    /**
     * GET请求
     * @param url 地址
     * @param data 参数
     * @param callback 成功回调
     * @param errorCallback 失败回调
     * @param autoMask 自动显示隐藏遮罩
     */
    static get(url, data, callback, errorCallback, autoMask) {
        Common.http("GET", url, data, callback, errorCallback, autoMask);
    }

    /**
     * POST请求
     * @param url 地址
     * @param data 参数
     * @param callback 成功回调
     * @param errorCallback 失败回调
     * @param autoMask 自动显示隐藏遮罩
     */
    static post(url, data, callback, errorCallback, autoMask) {
        Common.http("POST", url, data, callback, errorCallback, autoMask);
    }

    static postJson(url, data, callback, errorCallback, autoMask) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            }
        };
        Common.http(
            "POST",
            url,
            data,
            callback,
            errorCallback,
            autoMask,
            config
        );
    }

    static httpPost(url, data, callback, errorCallback, autoMask, config = {}) {
        Common.http(
            "POST",
            url,
            data,
            callback,
            errorCallback,
            autoMask,
            config
        );
    }

    static http(
        method,
        url,
        data,
        callback,
        errorCallback,
        autoMask,
        config = {}
    ) {
        if (autoMask === undefined) autoMask = true;
        if (autoMask) Common.loading(true, true);
        let run;
        if (!config.headers) {
            config.headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": "XMLHttpRequest"
            };
        }
        /* config.headers["Access-Control-Allow-Origin"] = "*"
        config.headers["Access-Control-Allow-Headers"] = "X-Requested-With,Content-Type"
        config.headers["Access-Control-Allow-Methods"] = "PUT,POST,GET,DELETE,OPTIONS" */

        method = method.toUpperCase();
        if (method === "GET") {
            config["params"] = data;
            run = axios.get(Common.BASE + url, config);
        } else if (method === "POST") {
            run = axios.post(Common.BASE + url, data, config);
        }

        if (run) {
            run.then(function(response) {
                if (autoMask) Common.loading(false, true);
                if (callback) callback(response.data);
            }).catch(function(err) {
                if (autoMask) Common.loading(false, true);
                if (errorCallback) errorCallback(err);
            });
        }
    }

    static toBool(prop) {
        if (Check.boolean(prop)) return prop;
        if (!prop || Common.trim(prop) === "") {
            return undefined;
        }
        return prop === "true" ? true : prop === "false" ? false : undefined;
    }

    static toJson(prop) {
        if (!Check.string(prop)) return prop;
        prop = Common.trim(prop);
        if (!prop || prop === "") {
            return undefined;
        }
        try {
            return JSON.parse(prop.replace(/'/g, '"'));
        } catch (e) {
            return prop;
        }
    }

    static openDialog(options, isTop = true) {
        options["isTop"] = isTop;
        if (options.width === undefined) {
            options.width = "800px";
        }

        if (options.height === undefined) {
            options.height = "600px";
        }

        if (options.full === undefined) {
            options.full = false;
        }
        options["origin"] = window;
        Options.maskWrap(isTop).openDialog(options);
    }

    static closeDialog(args, index) {
        Options.maskWrap(true).closeDialog(args, index);
    }

    static closeAllDialog() {
        Options.maskWrap(true).closeAllDialog();
    }

    static closeLocalDialog(args, index) {
        Options.maskWrap(false).closeDialog(args, index);
    }

    static openAlert(options) {
        Options.maskWrap(true).openAlert(options);
    }

    static closeAlert() {
        Options.maskWrap(true).closeAlert();
    }
    static closeAlbum() {
        Options.maskWrap(true).closeAlbum();
    }

    static alert(msg, autoHideTime) {
        Common.openAlert({
            title: "提示信息！",
            type: "info", // warn error info
            autoHideTime: autoHideTime,
            content: msg
        });
    }

    static error(msg, autoHideTime) {
        Common.openAlert({
            title: "错误！",
            type: "error", // warn error info
            autoHideTime: autoHideTime,
            content: msg
        });
    }

    static warn(msg, autoHideTime) {
        Common.openAlert({
            title: "警告！",
            type: "warn", // warn error info
            autoHideTime: autoHideTime,
            content: msg
        });
    }

    /**
     * loading 效果
     * @param show 是否显示
     * @param isTop {Boolean} 是否在顶层窗口
     */
    static loading(show, isTop = true) {
        Options.maskWrap(isTop).loading(show);
    }

    /**
     * 确认框
     * @param msg 信息内容
     * @param okFn 确认回调
     * @param cancelFn 取消回调
     */
    static confirm(msg, okFn, cancelFn) {
        Common.openAlert({
            title: "请确认！",
            type: "warn", // warn error info
            autoHideTime: -1,
            tools: [
                {
                    title: "确定",
                    fn: okFn,
                    icon: "check"
                },
                {
                    title: "取消",
                    fn: cancelFn,
                    icon: "close"
                }
            ],
            content: msg
        });
    }

    static openAlbum(list, current) {
        Options.maskWrap(true).openAlbum({
            list: list,
            current: current
        });
    }

    static closeAny() {
        let topMask = Options.maskWrap(true);
        let mask = Options.maskWrap(false);
        if (topMask === mask) {
            topMask.closeAny();
        } else {
            if (!mask.closeAny()) {
                topMask.closeAny();
            }
        }
    }

    static checkInputValue(value) {
        if (Check.string(value)) value = Common.trim(value);
        return (value ||
            value === null ||
            value === 0 ||
            value === "0" ||
            value === false ||
            value === "false" ||
            value === "FALSE") &&
            value !== "null"
            ? true
            : undefined;
    }

    static getMime(exts) {
        if (Check.array(exts)) {
            let arr = [];
            for (let ext of exts) {
                let m = MIME_TYPE[ext];
                if (!arr.includes(m)) {
                    arr.push(m);
                }
            }
            return arr;
        }
        return MIME_TYPE[exts];
    }

    static uuid(len, radix) {
        const CHARS = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
            ""
        );

        let chars = CHARS;
        let uuid = [];
        let i;
        radix = radix || chars.length;

        if (len) {
            // Compact form
            for (i = 0; i < len; i++) {
                uuid[i] = chars[0 | (Math.random() * radix)];
            }
        } else {
            // rfc4122, version 4 form
            var r;

            // rfc4122 requires these characters
            uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
            uuid[14] = "4";

            // Fill in random data.  At i==19 set the high bits of clock sequence as
            // per rfc4122, sec. 4.1.5
            for (i = 0; i < 36; i++) {
                if (!uuid[i]) {
                    r = 0 | (Math.random() * 16);
                    uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
                }
            }
        }

        return uuid.join("");
    }

    /**
     * 上传文件弹窗
     * @param config => objectId,objectType,allow,totalNum,callback,title,width,height
     */
    static updateWin(config) {
        let urlData = {};
        if (config["objectId"]) {
            urlData["objectId"] = config["objectId"];
        }
        if (config["objectType"]) {
            urlData["objectType"] = config["objectType"];
        }

        if (config["objectParams"]) {
            urlData["objectParams"] = JSON.stringify(config["objectParams"]);
        }

        if (config["allow"]) {
            urlData["allow"] = config["allow"];
        }
        if (config["totalNum"]) {
            urlData["totalNum"] = config["totalNum"];
        }

        if (config["totalSize"]) {
            urlData["totalSize"] = config["totalSize"];
        }

        if (config["singleSize"]) {
            urlData["singleSize"] = config["singleSize"];
        }

        if (config["disabled"]) {
            urlData["disabled"] = config["disabled"];
        }

        const param = qs.stringify(urlData);

        const url =
            "/attachment/docController/uploadWin" + (param ? "?" + param : "");
        Common.openDialog({
            url: url,
            title: config["title"] ? config["title"] : "上传",
            callback: function(result) {
                if (config["callback"]) {
                    config["callback"](result);
                }
            },
            width: config["width"] ? config["width"] : "500px",
            height: config["height"] ? config["height"] : "300px"
        });
    }

    static updateMountInfo(server, objectId, objectType, fileIds, callback) {
        if (!server) {
            server = "/attachment/docApiController/upload/updateMountInfo";
        }
        server =
            server.startsWith("http://") || server.startsWith("https://")
                ? server
                : Common.BASE + server;
        Common.post(
            server,
            {
                objectId: objectId,
                objectType: objectType,
                fileIds: fileIds + ""
            },
            result => {
                Common.doFn(callback, result);
            }
        );
    }

    static isInArray(elem, arr) {
        return arr.indexOf(elem) !== -1;
    }

    /**
     * 吊起支付页面
     * @param configId 支付配置id
     * @param orderNo 订单号 六位随机数+long型时间
     * @param subject 主题
     * @param body  描述
     * @param money 订单金额
     * @param extParamMap 支付宝官方接口参数
     * @param jsonParams 支付异步通知的时候会携带该参数返回
     */
    static invokePagePay(
        configId,
        orderNo,
        subject,
        body,
        money,
        extParamMap,
        jsonParams
    ) {
        var invokeUrl =
            "/third/pay/payController/doPay?configId=" +
            configId +
            "&orderNo=" +
            orderNo +
            "&subject=" +
            subject +
            "&body=" +
            body +
            "&money=" +
            money +
            "&extParamMap=" +
            extParamMap +
            "&callbackParamMap=" +
            jsonParams;
        window.open(encodeURI(invokeUrl));
    }

    /**
     * 批量转账
     * @param configId 支付配置id
     * @param no 批次号
     * @param num 总笔数
     * @param money 总金额
     * @param details &#100;&#101;&#116;&#97;&#105;&#108;&#115;&#10;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#91;&#123;&#10;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#97;&#99;&#99;&#111;&#117;&#110;&#116;&#32;&#25910;&#27454;&#26041;&#36134;&#21495;&#10;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#110;&#97;&#109;&#101;&#32;&#32;&#32;&#32;&#25910;&#27454;&#26041;&#21517;&#23383;&#10;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#109;&#111;&#110;&#101;&#121;&#32;&#32;&#32;&#36716;&#36134;&#37329;&#39069;&#10;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#114;&#101;&#109;&#97;&#114;&#107;&#32;&#32;&#36716;&#36134;&#35828;&#26126;&#10;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#125;&#93;
     * @param jsonParams 支付异步通知的时候会携带该参数返回
     */
    static invokeBatchTrans(configId, no, num, money, details, jsonParams) {
        var invokeUrl =
            "/third/pay/payController/doBatchTrans?configId=" +
            configId +
            "&no=" +
            no +
            "&num=" +
            num +
            "&details=" +
            JSON.stringify(details) +
            "&money=" +
            money +
            "&callbackParamMap=" +
            JSON.stringify(jsonParams);
        window.open(encodeURI(invokeUrl));
    }

    /**
     * 查询当前用户的支付配置
     *
     */
    static queryPaymentConfigs(callback) {
        Common.post(
            "/third/pay/paymentConfigApiController/queryPayments",
            {},
            result => {
                Common.doFn(callback, result);
            }
        );
    }

    static dateFormat(date, format) {
        return Common.DATE.format(date, format);
    }

    /**
     * @description 事件绑定，兼容各浏览器
     * @param target 事件触发对象
     * @param type 事件
     * @param func 事件处理函数
     */
    static addEvent(target, type, func) {
        if (target.addEventListener) {
            target.addEventListener(type, func, false);
        } else if (target.attachEvent) {
            target.attachEvent("on" + type, func);
        } else target["on" + type] = func; // ie5
    }

    /**
     * @description 事件移除，兼容各浏览器
     * @param target 事件触发对象
     * @param type 事件
     * @param func 事件处理函数
     */
    static removeEvent(target, type, func) {
        if (target.removeEventListener) {
            target.removeEventListener(type, func, false);
        } else if (target.detachEvent) {
            target.detachEvent("on" + type, func);
        } else target["on" + type] = null;
    }

    /**
     * 获取父级目标组件
     * @param type
     * @param com
     * @return {*}
     */
    static getTargetParentCom(type, com, maxLevel = 0, level = 0) {
        let parent = com.$parent;
        if (parent.cType === type) {
            return parent;
        } else {
            if (maxLevel !== 0 && maxLevel === level) {
                return undefined;
            } else {
                level++;
                return this.getTargetParentCom(type, parent, maxLevel, level);
            }
        }
    }

    /**
     * 对象转成 url 参数
     * { a: ['b', 'c'] } =>
     * @param obj 'a[0]=b&a[1]=c'
     */
    static objectToUrlParam(obj) {
        return "?" + qs.stringify(obj, { arrayFormat: "indices" });
    }

    static template(tpl, data) {
        const fetchValue = function(keyArr, value) {
            const key = keyArr.splice(0, 1);
            const newValue = value[key];
            if (!newValue) return "";
            if (Check.array(newValue)) {
                var value_i = "";
                for (var x = 0; x < newValue.length; x++) {
                    value_i += fetchValue(keyArr, newValue[x]);
                }
                return value_i;
            } else {
                if (keyArr.length === 0) {
                    return newValue;
                } else {
                    return fetchValue(keyArr, newValue);
                }
            }
        };
        let computed = tpl.replace(/\{((\w|\.)+)\}/g, function(match, key) {
            if (key.indexOf(".") !== -1) {
                return fetchValue(key.split("."), data);
            } else {
                return data[key] ? data[key] : "";
            }
        });
        return computed;
    }
}
