/**
 * 项目   vue
 * 作者   loufei
 * 时间   2018/2/19
 */
import Common from './common'
const DATE_FORMAT = Common.DATE_FORMAT;
const NUM_FORMAT = Common.NUM_FORMAT;
export default {
    datetime: {
        getMessage(field, args) {
            return `${field}:正确格式 <small>${DATE_FORMAT.datetime[0]}</small>`
        },
        validate(value, args) {
            return DATE_FORMAT.datetime[1].test(value)
        }
    },
    time: {
        getMessage(field, args) {
            return `${field}:正确格式 <small>${DATE_FORMAT.time[0]}</small>`
        },
        validate(value, args) {
            return DATE_FORMAT.time[1].test(value)
        }
    },
    month: {
        getMessage(field, args) {
            return `${field}:正确格式 <small>${DATE_FORMAT.month[0]}</small>`
        },
        validate(value, args) {
            return DATE_FORMAT.month[1].test(value)
        }
    },
    date: {
        getMessage(field, args) {
            return `${field}:正确格式 <small>${DATE_FORMAT.date[0]}</small>`
        },
        validate(value, args) {
            return DATE_FORMAT.date[1].test(value)
        }
    },
    num: {
        getMessage(field, args) {
            return `${field}:正确格式 <small>${NUM_FORMAT[args][0]}</small>`
        },
        validate(value, args) {
            return NUM_FORMAT[args[0]][1].test(Number(value))
        }
    }
}
