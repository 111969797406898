<template>
    <li :id="_comId" :class="`${__class}${hasDotLine?' dot-line ':''}` ">
        <div v-if="isShowRoot && real_level === node.level" :level="node.level" :class="`no-select tree-line-node${isFolder?' bold ':' '}${isChecked&&!isHalf ? ' checked ' :(isHalf?' half ':'')  }`" @click="itemClick(level_index)">
            <span v-if="hasCheck&&(node.key!=-1||node.key!='-1')" @click.capture.stop="toggleChecked"><i :class="`check-icon ${ this.isChecked&&!isHalf ? 'checked-icon' : (this.isChecked&&isHalf?'half-icon':'empty-icon') }`"></i></span>
            <span v-if="isFolder"><i :class="`folder-icon ${ open ? 'folder-close-icon' : 'folder-open-icon' }`"></i></span>
            <span v-show="showLoading"><i class="loading-icon"></i></span>
            <span class="text" @click.capture="toggle" ><i :class="`fa fa-${getIcon}`" />&nbsp;{{getTitle}}</span>
            <span class="tools" v-if="node.key!=-1">
                <jgp-btn v-for="(tool,index) in getTools" v-tooltip="tool.tip" :key="index" :_icon="tool.icon" :_fn="operateTreeNode(tool.fn,node)"
                _class="button-plain"  _margin="1" _size="tiny" _shape="rounded" >{{tool.name}}</jgp-btn>
            </span>
        </div>
        <ul v-show="open" v-if="isFolder">
            <jgp-menu-item
                v-for="(node, index) in node.children"
                :key="index"
                :_url="getUrl"
                :_check="hasCheck"
                :_single="single"
                :_lazy="isLazy"
                :_extend="isExtend"
                :_format="format"
                :_enable="isEnable"
                :_dot_line="hasDotLine"
                :_ajax_param="getAjaxParam"
                :_selected_keys="getSelectedKeys"
                :_onclick="onclick"
                :_node="node"
                :_tools="tools"
                :_real_level="real_level+1"
                :_fn="fn"
                :_level_index="`${index}`">
            </jgp-menu-item>
        </ul>
    </li>
</template>

<script>
import Check from 'check-types'
import Common from '../../utils/common'
/**
     *
     * 项目   jgp-front-pc
     * 作者   loufei
     * 时间   2018/9/5
     */
export default {
    data() {
        return {
            open: false,
            clickTime: null,
            showLoading: false
        }
    },
    props: {
        _node: Object,
        _ajax_param: String | Object,
        _check: String | Boolean,
        _single: String | Boolean,
        _enable: {
            type: String | Boolean,
            default: true
        },
        _lazy: {
            type: String | Boolean,
            default: false
        },
        _format: String,
        _extend: {
            type: String | Boolean,
            default: false
        },
        _dot_line: {
            type: String | Boolean,
            default: false
        },
        _url: String,
        _selected_keys: String | Array,
        _level_index: String,
        _onclick: String | Function,
        _tools: String | Object,
        _show_root: String | Boolean,
        _real_level: Number,
        _fn: String
    },
    computed: {
        getTitle() {
            if (this.format) {
                return Common.template(this.format, this.node.bean);
            } else {
                return this.node.title;
            }
        },
        isFolder: function () {
            return this.node.folder || (this.node.children && this.node.children.length > 0)
        },
        hasCheck() {
            return Common.toBool(this.check);
        },
        isLazy() {
            return Common.toBool(this.lazy);
        },
        isExtend() {
            return Common.toBool(this.extend);
        },
        isChecked() {
            return Common.toBool(this.node.is_checked);
        },
        isSingle() {
            return Common.toBool(this.single);
        },
        isHalf() {
            return this.isChecked && Common.toBool(this.node.is_half);
        },
        isEnable() {
            return Common.toBool(this.enable);
        },
        isDefaultChecked() {
            if (this.getSelectedKeys) {
                if (Check.array(this.getSelectedKeys)) {
                    return this.getSelectedKeys.indexOf(this.node.key) !== -1;
                } else {
                    return this.getSelectedKeys === this.node.key;
                }
            } else {
                return false;
            }
        },
        isShowRoot() {
            return !(this.node.key === '-1' && !Common.toBool(this.show_root));
        },
        hasDotLine() {
            return Common.toBool(this.dot_line);
        },
        getAjaxParam() {
            return Check.object(this.ajax_param) ? this.ajax_param : Common.toJson(this.ajax_param);
        },
        getSelectedKeys() {
            return Check.array(this.selected_keys) ? this.selected_keys : Common.toJson(this.selected_keys);
        },
        rootCom() {
            return this.getRootCom(this);
        },
        getTools() {
            return Common.toJson(this.tools)
        },
        getIcon() {
            return this.node.icon;
        },
        getUrl() {
            return this.url;
        }
    },
    watch: {
        'url': {
            handler: function() {
                this.load();
            }
        },
        'selected_keys': {
            handler: function() {
                const _this = this;
                _this.$nextTick(function () {
                    if (_this.isDefaultChecked) {
                        this.setSelected(true);
                    }
                })
            }
        },
        'node.is_checked': {
            handler: function (val, oldVal) {
                const _this = this;
                this.$nextTick(() => {
                    _this.$emit('checked-change', {
                        checked: val,
                        node: _this.node.bean
                    })
                    if ((_this.node.is_checked && _this.node.is_half) || _this.isSingle) return;
                    if (_this.hasCheck && _this.node.children) {
                        if (val) {
                            _this.node.children.forEach((childNode, i) => {
                                _this.$set(_this.node.children[i], 'is_checked', true);
                            })
                        } else {
                            _this.node.children.forEach((childNode, i) => {
                                _this.$set(_this.node.children[i], 'is_checked', false);
                            })
                        }
                    }
                })
            }
        },
        'node.children': {
            handler: function (val, oldVal) {
                const _this = this;
                this.$nextTick(() => {
                    if (_this.node.children && _this.node.children.length > 0) {
                        let childCheckedNum = 0;
                        let childHalfCheckedNum = 0;
                        _this.node.children.forEach((childNode) => {
                            if (childNode.is_checked || childNode.is_half) {
                                childCheckedNum++;
                                if (childNode.is_half) {
                                    childHalfCheckedNum++
                                }
                            }
                        })
                        if (!_this.isSingle) {
                            if ((val.length > childCheckedNum && childCheckedNum > 0) || childHalfCheckedNum > 0) {
                                _this.$set(_this.node, 'is_half', true);
                            } else {
                                _this.$set(_this.node, 'is_half', false);
                            }

                            if (childCheckedNum === val.length) {
                                _this.$set(_this.node, 'is_checked', true);
                            } else if (childCheckedNum === 0) {
                                _this.$set(_this.node, 'is_checked', false);
                            } else {
                                _this.$set(_this.node, 'is_checked', true);
                                _this.$set(_this.node, 'is_half', true);
                            }
                        }
                    }
                })
            },
            deep: true
        }
    },
    methods: {
        getRootCom(com) {
            let parent = com.$parent;
            if (parent.cType === 'jgp-menu') {
                return parent;
            } else {
                return this.getRootCom(parent)
            }
        },
        toggle: function (flag) {
            this.open = flag !== undefined ? flag : !this.open;

            if (this.open && this.isLazy && (!this.node.children || this.node.children.length === 0)) {
                this.load();
            }
        },
        clearChecked() {
            let children = this.$children;
            for (let child of children) {
                if (child.clearChecked) {
                    child.clearChecked();
                }
            }
            this.$set(this.node, 'is_checked', false);
        },
        checkedAll() {
            let children = this.$children;
            for (let child of children) {
                if (child.checkedAll) {
                    child.checkedAll();
                }
            }
            this.$set(this.node, 'is_checked', true);
        },
        toggleChecked() {
            if (!this.isEnable) return;
            this.$set(this.node, 'is_checked', !this.node.is_checked);
        },
        load(param) {
            const _this = this;
            if (!_this.getUrl) return;
            if (!param) {
                param = {}
            }
            const rootCom = _this.getRootCom(this);
            if (_this.node.folder || _this.isLazy) {
                rootCom.plusLoadCount();
                _this.showLoading = true;
                const ajaxParam = Object.assign({
                    parentId: _this.node.key ? _this.node.key : -1,
                    lazy: _this.isLazy
                }, _this.getAjaxParam, param)
                Common.get(_this.getUrl, ajaxParam, (result) => {
                    _this.$set(_this.node, 'children', result);
                    _this.showLoading = false;
                    rootCom.minusLoadCount();
                    rootCom.doOnload();
                    if (_this.node.key === '-1' && !this.isShowRoot) {
                        _this.toggle();
                    } else if (_this.isExtend) {
                        _this.toggle();
                    }
                }, (result) => {
                    _this.$set(_this.node, 'children', [])
                    _this.showLoading = false;
                    rootCom.minusLoadCount();
                    rootCom.doOnload();
                }, false)
            }
        },
        doShiftTextClick(level_index) {
            if (!this.isEnable) return;
            const children = this.$parent.$children.filter(vc => {
                return vc.hasClass('jgp-tree-item');
            });
            const current_index = Number(level_index);
            if (children && children.length > 0) {
                const levelSelecedIndexArr = children.filter(child => {
                    return child.isChecked || child.isHalf;
                }).map(child => {
                    return child.level_index;
                }).sort();
                if (levelSelecedIndexArr.length === 1) {
                    let min = Number(levelSelecedIndexArr[0]);

                    if (current_index < min) {
                        for (let i = current_index; i < min; i++) {
                            children[i].setSelected(true);
                        }
                    } else if (current_index > min) {
                        for (let i = min; i <= current_index; i++) {
                            children[i].setSelected(true);
                        }
                    }
                } else if (levelSelecedIndexArr.length > 1) {
                    let min = Number(levelSelecedIndexArr[0]);
                    let max = Number(levelSelecedIndexArr[1]);

                    if (current_index < min) {
                        for (let i = current_index; i < max; i++) {
                            children[i].setSelected(true);
                        }
                    } else if (current_index > max || (current_index > min && current_index < max)) {
                        for (let i = min; i <= current_index; i++) {
                            children[i].setSelected(true);
                        }
                    }
                }
            }
        },

        itemClick(level_index) {
            if (!this.isEnable) return;
            if (this.isSingle) {
                this.rootCom.clearChecked();
            }
            if (event.shiftKey === 1) {
                this.doShiftTextClick(level_index);
            } else {
                this.toggleChecked();
            }
            if (this.isLazy && (!this.node.children || this.node.children.length === 0)) {
                this.load();
            }
            if (this.real_level === 1) {
                const _this = this;
                let firstLevels = _this.$parent.$children.filter(child => {
                    return child.node.level === 1;
                })
                firstLevels.forEach(firstLevel => {
                    if (firstLevel.node.key !== _this.node.key) { firstLevel.toggle(false); }
                })
            }
            if (this.onclick) Common.doFn(this.onclick, this.node);
            /* if (this.clickTime) window.clearTimeout(this.clickTime);  //首先清除计时器
                 this.clickTime = window.setTimeout(() => {

                 }, 150);   //大概时间300ms */
        },
        setSelected(flag) {
            this.$set(this.node, 'is_checked', flag);
        },
        operateTreeNode(fn, node) {
            const _this = this;
            let _node = Object.assign({}, node);
            delete _node['children'];
            return function () { Common.doFn(fn, { component: _this, node: _node }) };
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {
    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {
    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {
    },
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
        const _this = this;
        _this.$nextTick(function () {
            if (_this.isDefaultChecked) {
                this.setSelected(true);
            }
        })

        if (_this.node.key === '-1') {
            _this.load();
        }
    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {
    },
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {

    },
    /* keep-alive 组件激活时调用。 */
    activated() {
    },
    /* keep-alive 组件停用时调用。 */
    deactivated() {
    },
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {
    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {
    }
}
</script>

<style>

</style>
