var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{on:{"after-enter":_vm.afterEnter,"after-leave":_vm.afterLeave}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.__show),expression:"__show"}],staticClass:"vdr",class:{
          draggable: _vm.draggable,
          resizable: _vm.resizable,
          active: _vm.enabled,
          dragging: _vm.dragging,
          resizing: _vm.resizing,
          animated:true
        },style:(_vm.getStyle),on:{"mousedown":_vm.elmDown,"touchstart":function($event){$event.stopPropagation();return _vm.elmDown($event)},"dblclick":_vm.fillParent}},[_vm._l((_vm.handles),function(handle){return (_vm.resizable)?_c('div',{key:handle,staticClass:"handle",class:'handle-' + handle,style:({ display: _vm.enabled ? 'block' : 'none'}),on:{"mousedown":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleDown(handle, $event)},"touchstart":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleDown(handle, $event)}}}):_vm._e()}),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }