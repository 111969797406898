<template>
    <div :id="_comId" :class="__class">
        <div class="tab-head">
            <div ref="scroll-wrap" class="scroll-content-wrap">
                <ul class="tab-head-item-wrap" ref="item-wrap">
                    <li :class="`no-select tab-head-item ${tab._comId==selectedKey&&!disabledTabs[tab._comId]?'selected':''} ${disabledTabs[tab._comId]?'disabled':''}`"
                        v-for="tab in getTabs"
                        :key="tab._comId"
                        @contextmenu.prevent="showMenu(tab._comId)"
                        @click="goPage(tab._comId)">
                        <span>{{tab.title}}</span>
                        <a class="close-wrap" v-if="getLockedKeys.indexOf(tab._comId)==-1"><i @click.stop="removeTab(tab._comId)" class="close fa fa-close"></i></a>
                    </li>
                </ul>
            </div>
            <div class="tab-head-tools">
                <!--<jgp-btn _icon="expand" _size="small" _margin="8px -5px 8px 15px" _color="" _shape="circle" :_fn="doMaxContentFn"></jgp-btn>-->
                <jgp-btn _icon="refresh" _size="small" _margin="8px 15px 8px 0px" _color="s" _shape="circle" :_fn="doRefreshContentFn"></jgp-btn>
            </div>
            <div class="fixed-min-btn" v-if="maxFlag">
                <jgp-btn _size="giant" _icon="expand" _color="" _shape="circle" :_fn="doMaxContentFn"></jgp-btn>
            </div>
        </div>

        <div class="tab-body" ref="body">
            <!-- @slot 内容 只能设置 JgpTab 为第一级子元素 -->
            <slot></slot>
            <jgp-tab :_id="tab.key" :key="tab.key" :_title="tab.title" :_url="tab.url" v-for="tab in dycTabs"></jgp-tab>
        </div>
        <div class="tab-menu no-select" ref="menu" v-show="showMenuFlag">
            <ul>
                <li class="menu-item" @click.stop="closeAll">关闭所有</li>
                <li class="menu-item" @click.stop="closeOther">关闭其他</li>
                <li class="menu-item" @click.stop="closeRight">关闭右侧</li>
                <li class="menu-item" @click.stop="closeLeft">关闭左侧</li>
            </ul>
        </div>
    </div>
</template>

<script>
import Check from 'check-types'
import Common from '../../utils/common'
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
class TabHeadScrollbarPlugin extends ScrollbarPlugin {
        static pluginName = 'TabHeadScrollbarPlugin';
        static defaultOptions = {
            y2x: false,
            xy: {}
        };

        transformDelta(delta, fromEvent) {
            if (this.options.y2x) {
                return {
                    x: delta.y
                };
            } else {
                return delta;
            }
        }
}

Scrollbar.use(TabHeadScrollbarPlugin);
/**
 * 选项卡面板
 *
 * @author 娄飞 【Gavin Lou】
 * @displayName JgpTabPanel
 */
export default {
    data() {
        return {
            maxFlag: false,
            tabs: [],
            dycTabs: [],
            disabledTabs: {},
            selectedKey: undefined,
            showMenuFlag: false
        }
    },
    props: {
        /**
         * 要锁定的key
         */
        _locked_keys: {
            type: String | Array,
            default: "[]"
        },
        /**
         * 最大tab数量
         */
        _max_size: {
            type: String | Number,
            default: 30
        }
    },
    computed: {
        getLockedKeys() {
            if (Check.string(this.locked_keys) && Common.trim(this.locked_keys)) {
                return Common.toJson(this.locked_keys);
            } else {
                return this.locked_keys;
            }
        },
        getMaxSize() {
            if (this.max_size) {
                return Number(this.max_size);
            }
        },
        getTabs() {
            return this.tabs.filter(tab => tab.cType === 'jgp-tab');
        },
        getTabMap() {
            let map = {};
            this.getTabs.forEach(tab => {
                map[tab._comId] = tab;
            })
            return map;
        }
    },
    methods: {
        goPage(key) {
            if (this.disabledTabs[key]) return;
            this.selectedKey = key;
            [].slice.call(this.getTabs).forEach((tab) => {
                if (tab._comId === key) {
                    tab.addClass('selected');
                } else {
                    tab.removeClass('selected');
                }
            })
        },
        getCurrent() {
            for (var i = 0; i < this.getTabs.length; i++) {
                const tab = this.getTabs[i];
                if (tab._comId === this.selectedKey) {
                    return tab;
                }
            }
        },
        removeTab(key) {
            let index = 0;
            let keys = Object.keys(this.getTabMap);
            index = keys.indexOf(key);
            let current = keys.indexOf(this.getCurrent()._comId);
            this.removeTabStatic(key);
            if (index === current) {
                this.$nextTick(() => {
                    let key = this.findPrevAvailablePageKey(index - 1) || this.findNextAvailablePageKey(index - 1);
                    if (key) this.goPage(key);
                })
            }
        },
        findPrevAvailablePageKey(index) {
            if (index === -1) {
                return undefined;
            }
            let key = this.getTabs[index]._comId;
            if (this.disabledTabs[key]) {
                return this.findPrevAvailablePageKey(index - 1);
            } else {
                return key;
            }
        },
        findNextAvailablePageKey(index) {
            if (index === this.getTabs.length) {
                return undefined;
            }

            if (index === -1) {
                index = 0;
            }
            let target = this.getTabs[index];
            if (!target) return undefined
            let key = target._comId;
            if (this.disabledTabs[key]) {
                return this.findNextAvailablePageKey(index + 1);
            } else {
                return key;
            }
        },
        addTab(key, title, url, open = true) {
            const _this = this;
            let hasFlag = false;
            for (var i = 0; i < _this.getTabs.length; i++) {
                const tab = _this.getTabs[i];
                if (tab._comId === key) {
                    hasFlag = true;
                }
            }
            if (hasFlag) {
                _this.$nextTick(() => {
                    if (open) _this.goPage(key);
                })
                return;
            }
            if (_this.getTabs.length >= _this.getMaxSize) {
                Common.confirm('任务栏数量已到上线<br>是否关闭最后一个任务面板', () => {
                    let keys = Object.keys(_this.getTabMap);
                    _this.removeTab(keys[keys.length - 1]);
                    _this.$nextTick(() => {
                        _this.addTab(key, title, url, open);
                    })
                });
                return;
            }
            _this.dycTabs.push({
                key, title, url
            })
            _this.$nextTick(() => {
                if (open) _this.goPage(key);
            })
        },
        disabled(...keys) {
            for (let key of keys) {
                this.disabledTabs[key] = true;
            }
        },
        doRefreshContentFn() {
            this.$nextTick(() => {
                const tab = this.getCurrent();
                const panel = tab.getNestedComByType('jgp-panel');
                panel[0].refresh();
            })
        },
        doMaxContentFn() {
            this.$nextTick(() => {
                const tab = this.getCurrent();
                const panel = tab.getNestedComByType('jgp-panel');
                panel[0].toggleMax();
                this.maxFlag = !this.maxFlag;
            })
        },
        showMenu(tabKey) {
            this.showMenuFlag = true;
            this.$refs.menu.tabKey = tabKey;
            // console.log(event,event.target.offsetWidth,event.target.offsetHeight)
            this.$refs.menu.style.top = event.clientY + 'px';
            this.$refs.menu.style.left = event.clientX + 'px';
        },
        removeTabStatic(key) {
            var _this = this;
            this.showMenuFlag = false;
            if (_this.getLockedKeys.indexOf(key) !== -1) return;
            _this.$nextTick(() => {
                _this.getTabMap[key].remove();
            })
        },
        findIndex(key) {
            var _this = this;
            var index;
            for (var i = 0; i < _this.getTabs.length; i++) {
                const tab = _this.getTabs[i];
                if (tab._comId === key) {
                    index = i;
                    break;
                }
            }
            return index;
        },
        closeAll() {
            var _this = this;
            _this.getTabs.forEach(tab => {
                let key = tab._comId;
                _this.removeTabStatic(key)
            })
            if (_this.getLockedKeys.length > 0) {
                if (!Check.array(_this.getLockedKeys)) {
                    _this.goPage(_this.getLockedKeys)
                } else {
                    _this.goPage(_this.getLockedKeys[_this.getLockedKeys.length - 1])
                }
            }
        },
        closeOther() {
            const _this = this;
            let targetKey = this.$refs.menu.tabKey;
            _this.getTabs.forEach(tab => {
                let key = tab._comId;
                if (key !== targetKey) _this.removeTabStatic(key)
            })
            _this.goPage(targetKey);
        },
        closeRight() {
            const _this = this;
            let targetKey = this.$refs.menu.tabKey;
            const keys = Object.keys(_this.getTabMap);
            let index = keys.indexOf(targetKey);
            if (keys.length !== index) {
                for (let i = index + 1; i < keys.length; i++) {
                    _this.removeTabStatic(keys[i]);
                }
            }
            _this.goPage(targetKey);
        },
        closeLeft() {
            const _this = this;
            let targetKey = this.$refs.menu.tabKey;
            const keys = Object.keys(_this.getTabMap);
            let index = keys.indexOf(targetKey);
            if (index !== 0) {
                for (let i = 0; i < index; i++) {
                    _this.removeTabStatic(keys[i]);
                }
            }
            _this.goPage(targetKey);
        },
        calcWidth() {
            this.$nextTick(() => {
                let sum = 0;
                let items = this.$el.querySelectorAll('.tab-head-item-wrap .tab-head-item');
                items.forEach(item => {
                    sum += item.offsetWidth + 1;
                })
                this.$refs['item-wrap'].style.width = sum + items.length + 'px'
            })
        },
        closeMenu() {
            this.showMenuFlag = false;
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {
    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {
        this.tabs = this.$children;
    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {
    },
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
        this.calcWidth();
        this.$nextTick(() => {
            this.goPage(this.getTabs[0]._comId);
            const scroll = this.$refs['scroll-wrap'];
            Scrollbar.init(scroll, {
                alwaysShowTracks: false,
                continuousScrolling: false,
                plugins: {
                    TabHeadScrollbarPlugin: {
                        y2x: true
                    }
                }
            })
        })
        Common.addEvent(document, 'click', this.closeMenu);
    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {
    },
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {
        this.calcWidth();
    },
    /* keep-alive 组件激活时调用。 */
    activated() {
    },
    /* keep-alive 组件停用时调用。 */
    deactivated() {
    },
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {
        Common.removeEvent(document, 'click', this.closeMenu);
    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {
    }
}
</script>

<style>

</style>
