<template>
    <jgp-text :_id="_comId" :class="__class" @onChange="onInputChange" @onBlur="onInputBlur" :_value="value" ref="text"
              :_label="label"
              :_label_width="label_width"
              :_name="name"
              :_col="col"
              :_required="required"
              :_disabled="getDisabled"
              :_readonly="getReadonly"
              :_validator="`${getValid}`"
              :_placeholder="placeholder"
              :_padding="padding"
              :_tip="tip"
              v-show="__show"
              :_submit="getSubmit"
              :_hasicon="hasUnit">

        <div slot="icon">
            <a class="icon-label" href="javascript:void(0);" @click="unitSelectShow=!unitSelectShow">
                <span class="icon"><b>数</b></span>
            </a>
        </div>
    </jgp-text>
</template>

<script>
/**
     * 数字组件
     * @module jgp-num
     * @desc 用于输入数字型文本
     * @example <jgp-num _label="数字" _name="number" _decimal="1"></jgp-num>
     */
import Common from '../../utils/common'
import Check from 'check-types'

// TODO xxxxx替换成store所属模块
export default {
    data() {
        return {
            hasUnit: 'false',
            unitSelectShow: false,
            units: Common.UNITS
        }
    },
    /**
         * @prop {String} _digits 整数位
         * @prop {String} _decimal 小数点后位数上线
         * @prop {String} _value 初始化值
         * @prop {String} _label 标题
         * @prop {String} _label_width 标题宽度
         * @prop {String} _name
         * @prop {String} _placeholder 提示内容
         * @prop {String} _tip 长文本提示 在LABEL hover的时候显示
         * @prop {String} _col 所占列宽
         * @prop {String} _required 是否可用 {默认:false}
         * @prop {String} _readonly 是否只读 {默认:false}
         * @prop {String} _disabled 是否禁用 {默认:false}
         * @prop {String} _onchange change事件
         * @prop {String} _padding 内边距 {默认(只负责 上、左、右):'5px 5px 0 5px'}
         */
    props: {
        '_decimal': String,
        '_digits': String,
        '_value': String | Number,
        '_placeholder': String,
        '_tip': String,
        '_label': String,
        '_name': String,
        '_col': {
            type: String | Number,
            default: '6'
        },
        '_required': {
            type: String | Boolean,
            default: 'false'
        },
        '_readonly': {
            type: String,
            default: 'false'
        },
        '_disabled': {
            type: String,
            default: 'false'
        },
        '_label_width': {
            type: String,
            default: '80px'
        },
        '_onchange': String,
        '_onblur': String,
        '_padding': {
            type: String,
            default: '5px 5px 0'
        },
        '_submit': {
            type: String | Boolean,
            default: 'true'
        }
    },
    computed: {
        getValid() {
            if (this.decimal && this.digits) {
                return 'decimal:' + this.decimal + '|digits:' + this.digits;
            } else if (this.decimal && !this.digits) {
                return 'decimal:' + this.decimal;
            } else if (this.digits && !this.decimal) {
                return 'digits:' + this.digits;
            } else {
                return 'numeric';
            }
        },
        getReadonly() {
            return (this.form && this.form.getReadonly) || Common.toBool(this.readonly)
        },
        getDisabled() {
            return (this.form && this.form.getDisabled) || Common.toBool(this.disabled)
        },
        getSubmit() {
            return Common.toBool(this.submit);
        }
    },
    methods: {
        getForm(com) {
            if (this.parentLevel > 10) {
                return undefined
            }
            let parent = com.$parent;
            if (parent.cType === 'jgp-form' || parent.cType === 'jgp-query') {
                return parent;
            } else {
                this.parentLevel += 1;
                return this.getForm(parent)
            }
        },
        getFormGroup(com) {
            let parent = com.$parent;
            if (this.parentLevel > 10 || parent.cType === 'jgp-form' || parent.cType === 'jgp-query') {
                return undefined
            }
            if (parent.cType === 'jgp-form-group') {
                return parent;
            } else {
                this.parentLevel += 1;
                return this.getFormGroup(parent)
            }
        },
        onInputChange(param) {
            this.value = Check.number(param.value) ? param.value : param.value.replace(/,/g, '');
            if (this.onchange) Common.doFn(this.onchange, param);
        },
        onInputBlur(param) {
            this.value = Check.number(param.value) ? param.value : param.value.replace(/,/g, '');
            if (this.onblur) Common.doFn(this.onblur, param);
        },
        /**
         * @name val
         * @function
         * @param value {Number}
         * @desc  获取值 JGP.num('id').val()。设置值 JGP.num('id').val(123)
         */
        val(value, submit) {
            if (Common.checkInputValue(value)) {
                this.$set(this, 'value', value);
            } else {
                return this.value;
            }
            if (typeof submit !== 'undefined') {
                this.$set(this, 'submit', submit);
            }
        },
        /**
             * @name reset
             * @function
             * @desc 清空已输入的内容
             */
        reset() {
            this.value = undefined;
        },
        valid() {
            return this.$refs.text.valid();
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {
    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {
    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {
    },
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {
    },
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {
    },
    /* keep-alive 组件激活时调用。 */
    activated() {
    },
    /* keep-alive 组件停用时调用。 */
    deactivated() {
    },
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {
    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {
    }
}
</script>
