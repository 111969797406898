/**
 * 项目   jgp-front-pc
 * 作者   loufei
 * 时间   2018/12/23
 */
import Common from './common'
/**
 *
 * @module Task
 */
export default class Task {
                   server;
                   constructor(server) {
                       this.server = server || "";
                   }

                   /**
                    * 查看流程定义
                    * @param {String}} defKey 流程定义标识
                    * @param {Object} windowConfig 弹窗设置
                    */
                   showProcessPicBeforeStart(defKey, windowConfig = {}) {
                       let config = {
                           url:
                               this.server +
                               "/workflow/processController/showProcessPicBeforeStart/" + defKey
                       };
                       windowConfig = Object.assign(windowConfig, config);
                       Common.openDialog(windowConfig);
                   }

                   /**
                    * 处理任务调用
                    * @name taskHandlerDialog
                    * @param buziConfig {procId,taskId,formEditFlag}
                    * @param windowConfig
                    */
                   taskHandlerDialog(buziConfig, windowConfig = {}) {
                       let config = {
                           url:
                               this.server +
                               "/workflow/processController/showTaskForm" +
                               Common.objectToUrlParam(buziConfig)
                       };
                       windowConfig = Object.assign(windowConfig, config);
                       Common.openDialog(windowConfig);
                   }

                   /**
                    *
                    * @param userId
                    * @param defKey
                    * @param businessKey
                    * @param jsonVariables json对象
                    * @param callback
                    */
                   start(userId, defKey, businessKey, jsonVariables, callback) {
                       let config = {
                           userId: userId,
                           defKey: defKey,
                           businessKey: businessKey
                       };
                       if (jsonVariables) {
                           config["jsonVariables"] = JSON.stringify(
                               jsonVariables
                           );
                       }
                       Common.post(
                           this.server + "/workflow/processApiController/start",
                           config,
                           result => {
                               if (callback) callback(result);
                           }
                       );
                   }

                   /**
                    * 提交申请
                    * @param userId
                    * @param procId
                    * @param taskId
                    * @param remark
                    * @param callback
                    */
                   submit(userId, procId, jsonVariables, callback) {
                       this.passWithVar(
                           userId,
                           procId,
                           null,
                           "提交申请",
                           jsonVariables,
                           callback
                       );
                   }

                   /**
                    * 不带参数的审批通过
                    * @param userId
                    * @param procId
                    * @param taskId
                    * @param remark
                    * @param callback
                    */
                   pass(userId, procId, taskId, remark, callback) {
                       this.passWithVar(
                           userId,
                           procId,
                           taskId,
                           remark,
                           null,
                           callback
                       );
                   }

                   /**
                    *
                    * 带参数审批通过
                    *
                    * @param userId
                    * @param procId
                    * @param taskId
                    * @param remark
                    * @param jsonVariables
                    * @param callback
                    */
                   passWithVar(
                       userId,
                       procId,
                       taskId,
                       remark,
                       jsonVariables,
                       callback
                   ) {
                       let config = {
                           userId: userId,
                           procId: procId,
                           taskId: taskId,
                           remark: remark
                       };
                       if (jsonVariables) {
                           config["jsonVariables"] = JSON.stringify(
                               jsonVariables
                           );
                       }
                       Common.post(
                           this.server + "/workflow/processApiController/pass",
                           config,
                           result => {
                               if (!result.flag && result.msgs) {
                                   Common.warn(result.msgs.join(","));
                               }
                               if (callback) callback(result);
                           }
                       );
                   }

                   /**
                    * 确认办理
                    * @param userId
                    * @param taskId
                    * @param remark
                    * @param callback
                    */
                   claim(userId, taskId, remark, callback) {
                       let config = {
                           userId: userId,
                           taskId: taskId,
                           remark: remark
                       };

                       Common.post(
                           this.server + "/workflow/processApiController/claim",
                           config,
                           result => {
                               if (!result.flag && result.msgs) {
                                   Common.warn(result.msgs.join(","));
                               }
                               if (callback) callback(result);
                           }
                       );
                   }

                   /**
                    * 取消办理
                    * @param userId
                    * @param taskId
                    * @param remark
                    * @param callback
                    */
                   unClaim(userId, taskId, remark, callback) {
                       let config = {
                           userId: userId,
                           taskId: taskId,
                           remark: remark
                       };

                       Common.post(
                           this.server +
                               "/workflow/processApiController/unClaim",
                           config,
                           result => {
                               if (!result.flag && result.msgs) {
                                   Common.warn(result.msgs.join(","));
                               }
                               if (callback) callback(result);
                           }
                       );
                   }

                   queryTasksByProc(procId, callback) {
                       Common.post(
                           this.server +
                               "/workflow/processApiController/queryTasksByProcForTree",
                           { procId: procId },
                           result => {
                               if (!result.flag && result.msgs) {
                                   Common.warn(result.msgs.join(","));
                               }
                               if (callback) callback(result);
                           }
                       );
                   }

                   /**
                    * 回退
                    * @param userId
                    * @param procId
                    * @param taskId
                    * @param remark
                    * @param callback
                    */
                   back(userId, procId, taskId, remark, callback) {
                       Common.post(
                           this.server + "/workflow/processApiController/back",
                           {
                               userId: userId,
                               procId: procId,
                               taskId: taskId,
                               remark: remark
                           },
                           result => {
                               if (!result.flag && result.msgs) {
                                   Common.warn(result.msgs.join(","));
                               }
                               if (callback) callback(result);
                           }
                       );
                   }

                   backTarget(
                       userId,
                       procId,
                       taskId,
                       targetTaskKey,
                       remark,
                       callback
                   ) {
                       Common.post(
                           this.server +
                               "/workflow/processApiController/backTarget",
                           {
                               userId: userId,
                               procId: procId,
                               taskId: taskId,
                               targetTaskKey: targetTaskKey,
                               remark: remark
                           },
                           result => {
                               if (!result.flag && result.msgs) {
                                   Common.warn(result.msgs.join(","));
                               }
                               if (callback) callback(result);
                           }
                       );
                   }
}
