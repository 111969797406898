<template>
    <div :id="_comId" :class="__class" :style="{width:width,height:height}">
        <table :class="direction">
            <tr :key="r_index" v-for="(r,r_index) in getRow">
                <td :key="c_index" v-for="(c,c_index) in getCol">
                    <div :class="`site-item ${siteStyle(r,c)} ${siteStatus(r,c)}`" @click="doClick(r,c)" :ref="`${r}-${c}`">
                        <span class="num">{{direction=='left'||direction=='right'?c+'排'+r+'号':r+'排'+c+'号'}}</span>
                        <span class="image"></span>
                        <span class="des">{{getPersonName(r,c)}}</span>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import Common from '../../utils/common'
import Check from 'check-types'

/**
     *
     * 项目   jgp-front-pc
     * 作者   loufei
     * 时间   2018/10/11
     */
export default {
    data() {
        return {
            sites: {}
        }
    },
    props: {
        _row: {
            type: String | Number,
            default: 1
        },
        _col: {
            type: String | Number,
            default: 1
        },
        _rotate: {
            type: String | Number,
            default: 0
        },
        _direction: {
            type: String,
            default: 'bottom' // top bottom left right
        },
        _persons: { // [{row:1,col:2,style:'1',name:'娄飞',tip:'说明'},{row:1,col:3,style:'2',name:'邢进',tip:'说明'}]  style : 1 2
            type: String | Array,
            default() {
                return [];
            }
        },
        _is_single: {
            type: String | Boolean,
            default: true
        },
        _readonly: {
            type: String | Boolean,
            default: false
        },
        _onclick: String
    },
    computed: {
        getRow() {
            let row = this.row;
            if (this.direction === 'left' || this.direction === 'right') {
                row = this.col;
            }
            if (Check.string(row)) {
                return Number(row);
            } else {
                return row;
            }
        },
        getCol() {
            let col = this.col;
            if (this.direction === 'left' || this.direction === 'right') {
                col = this.row;
            }
            if (Check.string(col)) {
                return Number(col);
            } else {
                return col;
            }
        },
        getRotate() {
            if (Check.string(this.rotate)) {
                return Number(this.rotate);
            } else {
                return this.rotate;
            }
        },
        getDirection() {
            return this.direction
        },
        getPersons() {
            let persons = [];
            let result = {};
            if (Check.string(this.persons)) {
                persons = Common.toJson(this.persons);
            } else if (Check.array(this.persons)) {
                persons = this.persons;
            }
            persons.forEach(person => {
                result[person.row + '-' + person.col] = {
                    name: person.name,
                    tip: person.tip,
                    style: person.style
                }
            })
            return result;
        },
        isSingle() {
            return Common.toBool(this.is_single);
        },
        getReadonly() {
            return Common.toBool(this.readonly);
        }
    },
    methods: {
        initSites() {
            let sites = {};

            for (let r = 1; r <= this.getRow; r++) {
                for (let c = 1; c <= this.getCol; c++) {
                    sites[r + '-' + c] = {
                        selected: false
                    }
                }
            }

            this.$set(this, 'sites', sites);
        },
        siteStyle(r, c) {
            const person = this.getPersons[this.direction === 'left' || this.direction === 'right' ? c + '-' + r : r + '-' + c];
            // let person = this.getPersons[r + '-' + c];
            if (person) {
                return 'status-' + person.style
            } else {
                return '';
            }
        },
        setPersons(persons) {
            this.$set(this, 'persons', persons);
        },
        setRow(row) {
            this.$set(this, 'row', row);
            this.initSites();
        },
        setCol(col) {
            this.$set(this, 'col', col);
            this.initSites();
        },
        siteStatus(r, c) {
            let site = this.sites[r + '-' + c];
            if (site && site.selected) {
                return ' selected ';
            } else {
                return '';
            }
        },
        check(r, c) {
            const person = this.getPersons[r + '-' + c];
            if (person) {
                return true;
            } else {
                return false;
            }
        },
        setSelected(r, c) {
            let sites = this.sites;
            if (this.isSingle) {
                for (let key in sites) {
                    if (key === (this.direction === 'left' || this.direction === 'right' ? c + '-' + r : r + '-' + c)) {
                        sites[key].selected = true;
                    } else {
                        sites[key].selected = false;
                    }
                }
                this.$set(this, 'sites', sites);
            } else {
                let site = sites[this.direction === 'left' || this.direction === 'right' ? c + '-' + r : r + '-' + c];
                site.selected = !site.selected;
                this.$set(this.sites, r + '-' + c, site);
            }
        },
        clearSelected() {
            let sites = this.sites;
            for (let key in sites) {
                sites[key].selected = false;
            }
            this.$set(this, 'sites', sites);
        },
        getSelected() {
            let selected = [];
            let sites = this.sites;
            for (let key in sites) {
                if (sites[key].selected) {
                    let s = key.split('-');
                    if (this.direction === 'left' || this.direction === 'right') {
                        selected.push([s[1], s[0]]);
                    } else {
                        selected.push(s);
                    }
                }
            }
            return selected.length === 1 ? selected[0] : selected;
        },
        doClick(r, c) {
            if (this.getReadonly) {
                return;
            }
            const person = this.getPersons[this.direction === 'left' || this.direction === 'right' ? c + '-' + r : r + '-' + c];
            if (person) {
                Common.warn("无法选取该座位");
                if (this.onclick) Common.doFn(this.onclick, { person });
                return;
            }

            let args = { r, c }
            if (this.direction === 'left' || this.direction === 'right') {
                args = { r: c, c: r }
            }
            this.setSelected(args.r, args.c)
            if (this.onclick) {
                const comId = this._comId;
                args['comId'] = comId;
                Common.doFn(this.onclick, args);
            }
        },
        getPersonName(r, c) {
            const person = this.getPersons[this.direction === 'left' || this.direction === 'right' ? c + '-' + r : r + '-' + c];
            if (person) {
                return person.name;
            }
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {
    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {
        this.initSites();
    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {
    },
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {

    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {
    },
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {

    },
    /* keep-alive 组件激活时调用。 */
    activated() {
    },
    /* keep-alive 组件停用时调用。 */
    deactivated() {
    },
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {
    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {
    }
}
</script>

<style>

</style>
