<template>
    <button type="button" :id="_comId" :class="`${__class} no-select ${col?'col-'+col:''} button ${isDisabled?'disabled':''} ${getColor?'button-'+getColor:''} ${size?'button-'+size:'button-'+''} ${shape?'button-'+shape:''}`"
        :style="{margin:margin,width:width,height:height}"
         v-show="__show"
         @click.stop="doAction">
        <i v-if="icon" :class="`fa fa-${icon}`"></i>
        <slot v-if="$slots.default&&$slots.default.length>0"></slot>
    </button>
</template>

<script>
/**
     * 按钮
     * @module ./components/button/JgpBtn
     * @desc 用于构建按钮
     * @example <jgp-btn _fn="submitForm">提交表单</jgp-btn>
     * @example 在[@JGP.foot]中的script标签内编辑对应的点击执行函数
     function submitForm() {}
     */
import Common from '../../utils/common'
import Options, { OPT } from '../../utils/options'
export default {
    /**
         * Props 接受父组件的传值
         * @prop {String} _icon 图标
         * @prop {String} _size 尺寸 giant jumbo large normal small tiny  默认在large与small中间
         * @prop {String} _color 颜色 primary plain inverse action highlight caution royal
         * @prop {String} _shape 形状 rounded圆角长方形 pill椭圆 box圆角正方形 square正方形 circle原型,默认长方形
         * @prop {String} _action 动作 url title ajaxParam ajaxType:只在ajax时有用post,get
         * @prop {String} _type 类型 normal open ajax 默认(normal)
         * @prop {String} _fn 执行方法,当_type=open 时为关闭窗口回调，当_type=normal 为执行方法,当_type=ajax时为请求完毕回调函数 'name:args' 即 abc:[1,2]  or {name:'abc',args:[1,2]}
         * @prop {String} _margin 外边距 {默认:'0px'} 若放到表单中需要设置margin为'5px 5px 0 5px'才能对齐元素
         * @prop {String} _col 所占列宽 如若放到表单中 有必要设置此属性
         */
    props: {
        /**
         * 图标
         */
        _icon: String,
        /**
         * 尺寸 giant jumbo large normal small tiny  默认在large与small中间
         */
        _size: {// plus large small tiny  默认在large与small中间
            type: String,
            default: 'small'
        },
        /**
         * 颜色 primary plain inverse action highlight caution royal
         */
        _shape: {// rounded圆角长方形 pill椭圆 box圆角正方形 square正方形 circle原型,默认长方形
            type: String,
            default: 'rounded'
        },
        /**
         * 形状 rounded圆角长方形 pill椭圆 box圆角正方形 square正方形 circle原型,默认长方形
         */
        _color: {
            type: String,
            default: 'primary'
        },
        /**
         * 动作 url title ajaxParam ajaxType:只在ajax时有用post,get
         */
        _action: String, // url title ajaxParam ajaxType:只在ajax时有用post,get
        /**
         * 类型 normal open ajax 默认(normal)
         */
        _type: {
            type: String,
            default: 'normal' // normal open ajax
        },
        /**
         * 执行方法,当_type=open 时为关闭窗口回调，当_type=normal 为执行方法,当_type=ajax时为请求完毕回调函数 'name:args' 即 abc:[1,2]  or {name:'abc',args:[1,2]}
         */
        _fn: [String, Object, Function], // 'name:args' 即 abc:[1,2]  or {name:'abc',args:[1,2]}
        /**
         * 传参
         */
        _bind_data: [String, Object],
        /**
         * 要提交的表单ID
         */
        _form: String, // 表单Id 用来提交表单
        /**
         * 外边距
         */
        _margin: {
            type: String,
            default: '5px'
        },
        /**
         * @ignore
         */
        _col: String,
        /**
         * 是否禁用
         */
        _disabled: {
            type: String | Boolean,
            default: 'false'
        }
    },
    computed: {
        isDisabled() {
            return Common.toBool(this.disabled)
        },
        getColor() {
            return this.color;
        },
        getAction() {
            return Common.toJson(this.action)
        }
    },
    methods: {
        doAction() {
            if (this.isDisabled) return;
            if (this.type === 'normal') {
                if (this.form) { // 如果按钮用来提交表单的话
                    const formCom = Options.com(OPT.FORM.type, this.form);
                    if (formCom) {
                        formCom.ajaxSubmit((result) => {
                            Common.doFn(this.fn, result, window);
                        });
                    }
                } else {
                    if (this.bind_data) {
                        Common.doFn(this.fn, Common.toJson(this.bind_data));
                    } else {
                        Common.doFn(this.fn);
                    }
                }
            } else if (this.type === 'open') {
                if (this.getAction && this.getAction !== {}) {
                    this.getAction['callback'] = this.fn
                    Common.openDialog(this.getAction)
                }
            } else if (this.type === 'ajax') {

            }
            this.$emit('click', this);
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {
    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {
        this.$nextTick(() => {
            if (!this.icon) {
                if (this.form) { // 如果按钮用来提交表单的话
                    this.icon = 'save'
                } else if (this.type === 'open') {
                    this.icon = 'bullseye'
                } else if (this.type === 'ajax') {
                    this.icon = 'spinner'
                }
            }
        })
    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {
    },
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {

    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {
    },
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {
    },
    /* keep-alive 组件激活时调用。 */
    activated() {
    },
    /* keep-alive 组件停用时调用。 */
    deactivated() {
    },
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {
    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {
    }
}
</script>

<style>

</style>
