// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import "babel-polyfill";
import Vue from "vue";
import Common from "./utils/common";
import Options from "./utils/options";
import "./assets/css/jgp.scss";
import FrameWork from "./FrameWork";

// 全局组件
FrameWork.MainMask = new Vue({
    el: "#jgp-mask-wrap"
});

FrameWork.MainView = new Vue({
    el: "#jgp-main-app",
    data() {
        return {
            data: FrameWork.mainViewData
        };
    },
    props: {
        text: String,
        default: "qwqe"
    },
    methods: {
        doFn(method, ...param) {
            Common.doFn(method, param);
        }
    },
    watch: {
        data: {
            handler() {
                this.$forceUpdate();
            },
            deep: true
        }
    },
    mounted() {
        document.onkeydown = function(e) {
            if (e && e.keyCode === 27) {
                Common.closeAny();
            }
        };
        let _this = this;
        this.$nextTick(function() {
            let timer = setTimeout(() => {
                let functions = Options.onloadFnc();
                for (let func of functions) {
                    func(_this);
                }
                clearTimeout(timer);
            }, 150);
        });
    }
});

export default FrameWork;
