import Check from 'check-types'
import Common from './common';
import Options, { OPT } from './options';
import XLSX from 'xlsx';

/* var aoa = [
    ['姓名', '性别', '年龄', '注册时间'],
    ['张三', '男', 18, new Date()],
    ['李四', '女', 22, new Date()]
]; */

function openDownloadDialog(url, saveName) {
    if (typeof url === 'object' && url instanceof Blob) {
        url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement('a');
    aLink.href = url;
    aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) event = new MouseEvent('click');
    else {
        event = document.createEvent('MouseEvents');
        event.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    }
    aLink.dispatchEvent(event);
}

// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
function sheet2blob(sheet, sheetName) {
    sheetName = sheetName || 'sheet1';
    var workbook = {
        SheetNames: [sheetName],
        Sheets: {}
    };
    workbook.Sheets[sheetName] = sheet;
    // 生成excel的配置项
    var wopts = {
        bookType: 'xlsx', // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: 'binary'
    };
    var wbout = XLSX.write(workbook, wopts);
    var blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    // 字符串转ArrayBuffer
    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
    return blob;
}

function getDisplayValue(cat, record) {
    var key = cat.name;
    var value = Object.assign({}, record.dataMap); var displayValue = cat.mapperValues;
    if (key.indexOf('.') !== -1) {
        var arr = key.split('.')
        arr.forEach(k => {
            if (!!value && JSON.stringify(value) !== "{}") {
                if (!!cat.hasMapper && !!displayValue && JSON.stringify(displayValue) !== "{}") {
                    const disv = displayValue[value[k]];
                    value = disv || value[k];
                } else {
                    value = value[k];
                }
            }
        })
    } else {
        if (!!value && JSON.stringify(value) !== "{}") {
            if (!!cat.hasMapper && !!displayValue && JSON.stringify(displayValue) !== "{}") {
                let disv = "";
                if (!!value[key] && !Check.number(value[key]) && value[key].indexOf(',') !== -1) {
                    const vls = value[key].split(',');
                    for (let vl of vls) {
                        disv += displayValue[vl] + ",";
                    }
                    disv = disv.substring(0, disv.length - 1);
                } else {
                    disv = displayValue[value[key]];
                }
                value = disv || value[key];
            } else {
                value = value[key];
            }
        }
    }
    return value;
}

function mapperValues(cat) {
    const name = cat.mapperName;
    const relationName = cat.relationName;
    if (cat.displayValues) {
        let values = {};
        for (let value of cat.displayValues) {
            values[value[relationName]] = value[name];
        }
        return values;
    } else {
        return cat.mapperValues;
    }
}

/**
 * 利用 query 组件导出excel
 * @param {String} queryId query组件的id
 * @param {String} fileName 导出文件名
 * @param {Boolean} all 导出全部
 * @param {Function} sucessCallback 成功回调
 * @param {Function} errorCallback 失败回调
 */
export function exportExcelByQuery(queryId, fileName, all, sucessCallback, errorCallback) {
    const queryCom = Options.com(OPT.QUERY.type, queryId);
    let query = Common.getFormData(queryCom.$refs.form);

    const resultFn = function(result) {
        let list = [];
        if (result.flag) {
            const gdata = JSON.parse(result.data["gdata"]);
            const cats = gdata.cats;
            let titles = ["唯一标识"];
            let names = ["id"];
            let category = [
                {
                    name: "id",
                    hasMapper: false
                }
            ];
            let checkId = false;
            for (let cat of cats) {
                if (cat.name === "virtualFormResponseData.bizId") {
                    checkId = true;
                }
            }

            if (checkId) {
                names = ["virtualFormResponseData.bizId"];
                category = [
                    {
                        name: "virtualFormResponseData.bizId",
                        hasMapper: false
                    }
                ];
            }
            for (let cat of gdata.cats) {
                let newCat = {};
                newCat["name"] = cat.name;
                newCat["title"] = cat.title;
                titles.push(cat.title);
                names.push(cat.name);
                if (cat.hasMapper) {
                    let values = mapperValues(cat);
                    newCat["mapperValues"] = values || {};
                    newCat["hasMapper"] = true;
                    newCat["mapperName"] = cat.mapperName;
                }
                category.push(newCat);
            }
            list.push(titles);
            list.push(names);
            let records = [];
            for (let r of gdata.records) {
                let record = {
                    dataMap: {},
                    fieldMap: {}
                };
                for (let field of r.fields) {
                    record.dataMap[field.name] = Common.checkInputValue(
                        field.value
                    )
                        ? field.value
                        : "";
                    record.fieldMap[field.name] = field;
                }
                records.push(record);
            }

            for (let record of records) {
                var res = [];
                if (checkId) {
                    res.push(record.dataMap.virtualFormResponseData.bizId);
                }
                category.forEach(cat => {
                    res.push(getDisplayValue(cat, record));
                });
                list.push(res);
            }

            var sheet = XLSX.utils.aoa_to_sheet(list);
            openDownloadDialog(sheet2blob(sheet), fileName + ".xlsx");
        }
        if (sucessCallback) Common.doFn(sucessCallback, result);
    };

    if (queryCom.needRenderForm) {
        let filters = [];
        for (let key in query) {
            if (key !== 'pager.current' && key !== 'pager.pageSize' && key !== 'formCode' && key !== 'orderAttr' && key !== 'orderDirection') {
                if (query[key]) {
                    filters.push({
                        name: key,
                        operator: queryCom.formAttrMap[key] ? queryCom.formAttrMap[key].queryOperator : undefined,
                        value: query[key]
                    })
                }
                delete query[key];
            }
        }
        query['filters'] = filters;
        if (!all) {
            query['pager'] = {
                current: query['pager.current'],
                pageSize: query['pager.pageSize']
            }
        }
        delete query['pager.current'];
        delete query['pager.pageSize'];
        Object.assign(query, {
            formId: queryCom.render_form_id,
            formCode: queryCom.render_form_code
        })
        Common.postJson(queryCom.action, query, resultFn, errorCallback);
    } else {
        if (all) {
            delete query["pager.current"];
            delete query["pager.pageSize"];
        }
        Common.post(queryCom.action, query, resultFn, errorCallback);
    }
}

export function exportGridData(id, fileName) {
    const grid = Options.com(OPT.GRID.type, id);
    const cats = grid.getFieldCategory;
    let list = [];
    let titles = ["唯一标识"];
    let names = ["id"];
    let category = [
        {
            name: "id",
            hasMapper: false
        }
    ];
    let checkId = false;
    for (let cat of cats) {
        if (cat.name === "virtualFormResponseData.bizId") {
            checkId = true;
        }
    }

    if (checkId) {
        names = ["virtualFormResponseData.bizId"];
        category = [
            {
                name: "virtualFormResponseData.bizId",
                hasMapper: false
            }
        ];
    }

    for (let cat of cats) {
        let newCat = {};
        newCat["name"] = cat.name;
        newCat["title"] = cat.title;
        titles.push(cat.title);
        names.push(cat.name);
        if (cat.hasMapper) {
            let values = mapperValues(cat);
            newCat["mapperValues"] = values || {};
            newCat["hasMapper"] = true;
            newCat["mapperName"] = cat.mapperName;
        }
        category.push(newCat);
    }
    list.push(titles);
    list.push(names);
    for (let index = 0; index < grid.records.length; index++) {
        let record = grid.records[index];
        var res = [];
        if (checkId) {
            res.push(record.dataMap.virtualFormResponseData.bizId);
        }
        category.forEach(cat => {
            res.push(getDisplayValue(cat, record));
        });
        list.push(res);
    }
    var sheet = XLSX.utils.aoa_to_sheet(list);
    openDownloadDialog(sheet2blob(sheet), fileName + '.xlsx');
}
