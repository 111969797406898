/**
 * 项目   vue
 * 作者   loufei
 * 时间   2018/3/1
 */
export default class Constants {
    static FORMAT = {
        DATE: {
            date: ['YYYY-MM-DD', /^[1-9]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/],
            time: ['HH:mm:ss', /^(20|21|22|23|[0-1][0-9]):[0-5][0-9]:[0-5][0-9]$/],
            month: ['MM', /^(0[1-9]|1[0-2])$/],
            datetime: ['YYYY-MM-DD HH:mm:ss', /^(((20[0-3][0-9]-(0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01]))|(20[0-3][0-9]-(0[2469]|11)-(0[1-9]|[12][0-9]|30))) (20|21|22|23|[0-1][0-9]):[0-5][0-9]:[0-5][0-9])$/],
            ym: ['YYYY-MM', /^\d{4}-((0([1-9]))|(1(0|1|2)))$/]
        },
        NUM: {
            int: ['整数', /^-?\d+$/],
            zint: ['正整数', /^[0-9]*[1-9][0-9]*$/],
            fint: ['负整数', /^-[0-9]*[1-9][0-9]*$/],
            float: ['浮点数', /^(-?\d+)(\.\d+)?$/],
            zfloat: ['正浮点数', /^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$/],
            ffloat: ['负浮点数', /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/]
        }
    }

    static EMIT = {
        PROPS_CHANGE: "onPropsChange"
    }
    static UNITS = {
        money: [{
            name: '人民币',
            icon: 'cny',
            regex: ''
        }, {
            name: '美元',
            icon: 'dollar',
            regex: ''
        }, {
            name: '英镑',
            icon: 'gbp',
            regex: ''
        }, {
            name: '欧元',
            icon: 'eur',
            regex: ''
        }]
    }
}
