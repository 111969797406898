<template>
    <jgp-text  :_id="_comId" :class="__class" @onChange="onInputChange" @onFocus="pileFocus" @onBlur="pileBlur" :_value="value"
               :_label="label"
               :_label_width="label_width"
               :_col="col"
               :_required="required"
               :_disabled="getDisabled"
               :_readonly="getReadonly"
               :_validator="`${getValid}`"
               :_placeholder="placeholder"
               :_padding="padding"
               :_tip="tip"
               :_name="name+'-no-submit'"
               :_height="height"
               :_width="width"
               v-show="__show"
               :_submit="false">
        <input slot="hidden" :data-submit="getSubmit" type="hidden" :name="name" v-model="realValue">
    </jgp-text>
</template>

<script>
import Common from '../../utils/common'
/**
     *
     * 项目   jgp-front-pc
     * 作者   loufei
     * 时间   2019/3/19
     */
export default {
    data() {
        return {
            realValue: undefined
        }
    },
    props: {
        '_decimal': String,
        '_value': String | Number,
        '_placeholder': String,
        '_tip': String,
        '_label': String,
        '_name': String,
        '_col': {
            type: String,
            default: '6'
        },
        '_required': {
            type: String,
            default: 'false'
        },
        '_readonly': {
            type: String,
            default: 'false'
        },
        '_disabled': {
            type: String,
            default: 'false'
        },
        '_label_width': {
            type: String,
            default: '80px'
        },
        '_onchange': String,
        '_padding': {
            type: String,
            default: '5px 5px 0'
        },
        '_submit': {
            type: String | Boolean,
            default: 'true'
        }
    },
    computed: {
        getValid() {
            let str = "";
            if (this.getRequired && this.validator) {
                str = 'required|' + this.validator
            } else if (!this.getRequired && this.validator) {
                str = this.validator;
            } else if (this.getRequired) {
                str = "required"
            }
            if (this.getDisabled) {
                str = ""
            }
            return str;
        },
        getReadonly() {
            return (this.form && this.form.getReadonly) || Common.toBool(this.readonly)
        },
        getDisabled() {
            return (this.form && this.form.getDisabled) || Common.toBool(this.disabled)
        },
        form() {
            return this.getForm(this);
        },
        formGroup() {
            return this.getFormGroup(this);
        },
        getSubmit() {
            return Common.toBool(this.submit);
        }
    },
    watch: {
        realValue(val) {
            this.pileBlur({ value: val }, true);
        }
    },
    methods: {
        getForm(com) {
            if (this.parentLevel > 10) {
                return undefined
            }
            let parent = com.$parent;
            if (parent.cType === 'jgp-form' || parent.cType === 'jgp-query') {
                return parent;
            } else {
                this.parentLevel += 1;
                return this.getForm(parent)
            }
        },
        getFormGroup(com) {
            let parent = com.$parent;
            if (this.parentLevel > 10 || parent.cType === 'jgp-form' || parent.cType === 'jgp-query') {
                return undefined
            }
            if (parent.cType === 'jgp-form-group') {
                return parent;
            } else {
                this.parentLevel += 1;
                return this.getFormGroup(parent)
            }
        },
        onInputChange(param) {
            if (this.onchange) Common.doFn(this.onchange, param);
        },
        /**
             * @name val
             * @function
             * @param value {Number}
             * @desc  获取值 JGP.num('id').val()。设置值 JGP.num('id').val(123)
             */
        val(value, submit = true) {
            if (Common.checkInputValue(value)) {
                this.$set(this, 'realValue', value);
            } else {
                return this.realValue;
            }
            this.$set(this, 'submit', submit);
        },
        /**
             * @name reset
             * @function
             * @desc 清空已输入的内容
             */
        reset() {
            this.value = undefined;
        },
        toKPile(value) {
            if (!value) return undefined;
            value = value + '';
            value = value.replace(' ', '');
            if (value.indexOf('.') !== -1) {
                let arr = value.split('.');
                let mile = parseInt(Number('0.' + arr[1]) * 1000);
                const len = (mile + '').length;
                return 'K ' + arr[0] + '+' + (len === 1 ? '00' + mile : len === 2 ? '0' + mile : mile);
            } else {
                return 'K ' + value + '+' + '000';
            }
        },
        toRealPile(value) {
            if (!value) return undefined;
            value = value + '';
            if (value.indexOf('+') === -1) return;
            value = value.replace(' ', '').replace('K', '');
            let arr = value.split('+');
            return Number(arr[0]) + Number(arr[1].substr(0, 3) / 1000);
        },
        pileFocus(param) {
            if (this.getReadonly || this.getDisabled) {
                return;
            }
            if (!param.value) return undefined;
            this.value = this.toRealPile(param.value);
        },
        pileBlur(param, force) {
            if (!force && (this.getReadonly || this.getDisabled)) {
                return;
            }
            if (!param.value) {
                this.realValue = undefined;
                return;
            }
            this.value = this.toKPile(parseFloat(param.value));
            let realValue = this.toRealPile(this.value) + '';
            if (this.realValue !== realValue) {
                this.realValue = realValue;
            }
        },
        valid() {
            return this.$children[0].$validator.validate();
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {
    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {
        if (this.form) { this.form.els.push({ name: this.name, el: this }); }
    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {
    },
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
        this.$nextTick(() => {
            this.realValue = this.value;
        })
    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {
    },
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {
    },
    /* keep-alive 组件激活时调用。 */
    activated() {
    },
    /* keep-alive 组件停用时调用。 */
    deactivated() {
    },
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {
    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {
    }
}
</script>

<style>

</style>
